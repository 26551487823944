<template>
    <button @click="open" class="session_item">
        <div class="session_item_avatar" v-if="playing.mode === 'passive'">nights_stay</div>
        <img :src="playing.song.cover" class="session_item_cover" alt="cover" v-if="playing.mode === 'active'"/>
        <div class="session_item_detail">
            <span class="session_item_title">{{title}}</span>
            <span class="session_item_desc">{{active}} active</span>
        </div>
        <span :class="'session_item_arrow' + (this.ssid === this.uuid ? ' selected' : '')">
            {{this.ssid === this.uuid ? 'trip_origin' : 'keyboard_arrow_right'}}
        </span>
    </button>
</template>

<script>
    export default {
        name: "SessionItem",

        props: ['uuid', 'title', 'mode', 'active', 'playing'],

        computed: {
            ssid() {
                return this.$route.params.ssid;
            },

            isApp() {
                return this.$route.name === 'app';
            }
        },

        data() {
            return {
            }
        },

        methods: {
            open() {
                const name = this.isApp ? 'app-session' : 'session';

                this.$router.push({name: name, params: {ssid: this.uuid}});
            },
        },

        mounted() {
        },
    }
</script>
