<template>
    <div class="friend_item">
        <span class="friend_item_name">{{user.username}}</span>
        <button class="friend_item_action" @click="sendInvite">{{current_icon}}</button>
    </div>
</template>
<script>
    // ToDo: FRIEND CSS CLASSES
    export default {
        name: "InviteItem",
        props: ['user', 'ssid'],

        data() {
            return {
                actions: {
                    send: {icon: 'send'},
                    done: {icon: 'check'},
                    failed: {icon: 'warning'}
                },

                current_icon: 'send',
            }
        },

        methods: {
            sendInvite() {
                this.$store.dispatch('sendInvite', {invitedID: this.user.id, ssid: this.ssid}).then((data) => {
                    this.current_icon = this.actions.done.icon;
                }).catch((error) => {
                    this.current_icon = this.actions.failed.icon;
                });
            }
        },
    }
</script>
