<template>
    <div class="toggle_view_page">
        <input type="search" placeholder="Search" v-model="query" />
        <div>
            <SongItem :song="song" :key="index" v-for="(song, index) in items" @update="$emit('update')"></SongItem>
        </div>
    </div>
</template>

<script>
    import SongItem from "../../elements/SongItem.vue";
    export default {
        name: "Search",
        components: {SongItem},
        data() {
            return {
                query: '',
                items: [],
                timeout: null,
            }
        },

        methods: {
            search() {
                this.$store.dispatch('searchSongs', this.query).then((data) => {
                    this.items = data.data;
                });
            }

        },

        watch: {
            query() {
                clearTimeout(this.timeout);

                if (this.query !== '') {
                    this.timeout = setTimeout(this.search, 800);
                }
            }
        }
    }
</script>