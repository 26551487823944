<template>
    <Card title="Settings">
        <div v-for="entry in layout">
            <DoubleNav @click="activateNavEntry(entry)" :title="entry.title" :desc="entry.desc"/>
        </div>
    </Card>
</template>

<script>
    import Back from "../elements/Back.vue";
    import DoubleNav from "../elements/DoubleNav.vue";
    import Card from "../elements/Card.vue";

    const SETTING_TYPE = {
        SUB: 'sub'
    };

    const SETTING_PROP = {
        [SETTING_TYPE.SUB]: {}
    }

    export default {
        name: "Settings",
        components: {Card, DoubleNav, Back},

        computed: {
            user() {
                return this.$store.getters.user;
            },
        },

        data() {
            return {
                layout: [
                    {type: SETTING_TYPE.SUB, title: 'Setup', desc: 'Setup', path: 'setup'},
                ]
            }
        },

        methods: {
            activateNavEntry(entry) {
                if (entry.params) {
                    this.$router.push({name: entry.path, params: entry.params});
                } else {
                    this.$router.push({name: entry.path});
                }
            },

            activateSuper() {
                if(this.user.level === 735) {
                    this.layout.push({type: SETTING_TYPE.SUB, title: 'Ticket', desc: 'Create New Beta Ticket', path: 'browse-act', params: {action: 'ticket'}})
                }
            }
        },

        watch: {
            user() {
                this.activateSuper();
            },
        },

        mounted() {
            this.activateSuper();
        }
    }
</script>
