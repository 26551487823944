<template>
    <div class="site">
        <header class="site_header">
            <img class="site_logo" src="/img/logo-cover.png" alt="SYMPE Logo"/>
            <span class="site_name">SYMPE</span>
        </header>
        <router-view></router-view>
        <nav class="site_nav" v-if="showNav">
            <div :class="navClass(item)" v-for="item in nav" @click="$router.push({name: item.route})">
                <div class="site_nav_tile"></div>
                <div class="site_nav_title_wrap"><span class="site_nav_title">{{item.name}}</span></div>
            </div>
        </nav>
    </div>
</template>

<script>
    export default {
        name: "Site",

        computed: {
            route() {
                return this.$route.name;
            },

            showNav() {
                return this.$route.name !== 'onboarding' && this.$route.name !== 'loader';
            },
        },

        data() {
            return {
                current: 'login',

                nav: [
                    {name: 'Join Us', route: 'join'},
                    {name: 'How To', route: 'how'},
                    {name: 'Login', route: 'login'},
                    {name: 'About', route: 'about'},
                    {name: 'More', route: 'details'},
                ],
            }
        },

        methods: {
            navClass(item) {
                if (item.route === this.route) {
                    return 'site_nav_item active';
                }

                return 'site_nav_item';
            }
        }
    }
</script>