<template>
    <div class="site_content_wrap">
        <main class="site_content">
            <h1 class="site_headline">Listening to music jointly has never been easier.</h1>
            <HowToView></HowToView>
        </main>
    </div>
</template>

<script>
    import HowToView from "../elements/HowToView.vue";

    export default {
        name: "How",

        components: {HowToView},

        computed: {
        },

        data() {
            return {
            }
        },

        methods: {

        },
    }
</script>