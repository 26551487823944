<template>
    <div :class="isApp ? 'card card_full' : 'card'">
        <div class="card_title">
            <input type="search" class="input_spread card_title_input" placeholder="Find Friends" v-model="query" ref="search" @blur="leave" />
            <button class="input_button" @click="buttonClicked">{{button_char}}</button>
        </div>
        <div class="card_body">
            <FriendItem :key="index" :user="friend" v-for="(friend, index) in results"></FriendItem>
        </div>
    </div>
</template>

<script>
    import FriendItem from "../../elements/FriendItem.vue";

    export default {
        name: "FriendSearch",
        components: {FriendItem},
        data() {
            return {
                query: '',
                results: [],
                timeout: {},
                button_char: 'search',
            }
        },

        computed: {
            friends() {
                return this.$store.getters.friends;
            },

            isApp() {
                return this.$route.name.includes('app');
            }
        },

        methods: {
            buttonClicked() {
                this.$refs.search.focus();

                if (this.query !== '') {
                    this.query = '';
                }
            },

            awaitFind() {
                clearTimeout(this.timeout);

                this.timeout = setTimeout(() => {
                    if (this.query !== '') {
                        this.find();
                    }
                }, 800);
            },

            find() {
                this.results = [];
                this.$store.dispatch('findFriends', this.query).then((data) => {
                    this.results = data;
                });
            },

            leave() {
                if (this.query === '') {
                    const name = this.isApp ? 'app-friends' : 'friends';

                    this.$router.push({name: name});
                }
            }
        },

        watch: {
            friends() {
                this.find();
            },

            query(to) {
                if (to === '') {
                    this.button_char = 'search';
                } else {
                    this.awaitFind();

                    this.button_char = 'cancel';
                }
            }
        },

        mounted() {
            this.$refs.search.focus();
        }
    }
</script>
