<template>
    <form ref="main" @submit="submit">
        <slot></slot>
    </form>
</template>

<script>
    import {FORM_MODE} from "../../../objects/form";

    const MODE_FEATURES = {
        [FORM_MODE.DEFAULT]: {

        },
        [FORM_MODE.LOADING]: {

        },
        [FORM_MODE.RETRY]: {

        }
    };

    export default {
        name: "Form",

        props: ['initial'],

        data() {
            return {
                mode: FORM_MODE.DEFAULT,
                current: {},
            }
        },

        computed: {
            hasChanged() {
                return JSON.stringify(this.initial) !== JSON.stringify(this.current);
            }
        },

        methods: {
            submit(event) {
                event.preventDefault();
                this.$refs.main.reportValidity();

                if (this.$refs.main.checkValidity()) {
                    this.mode = FORM_MODE.LOADING;
                    this.$emit('submit', this.current);
                } else {
                    this.mode = FORM_MODE.RETRY;
                    this.$emit('invalid', this.current);
                }

                this.emitMode();
            },

            emitMode() {
                this.$emit('mode', this.mode);
            },

            setValue(name, value) {
                this.current = {...this.current, [name]: value};
            },

            setMode(mode) {
                this.mode = mode;
                this.emitMode();
            }
        },
    }
</script>
