<template>
    <div class="toggle_view_page">
        <Active v-if="!show_invites" :openInvites="openInvites"></Active>
        <Invite v-else :hide="hideInvites"></Invite>
    </div>
</template>

<script>
    import Active from "./Active.vue";
    import Invite from "./Invite.vue";

    export default {
        name: "Social",
        components: {Invite, Active},

        data() {
            return {
                show_invites: false,
            }
        },

        methods: {
            openInvites() {
                this.show_invites = true;
            },

            hideInvites() {
                this.show_invites = false;
            },
        },

        mounted() {
            this.hideInvites();
        },
    }
</script>