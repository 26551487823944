<template>
    <div :class="wrapClass" ref="wrap">
        <div class="session_background_wrap">
            <img class="session_background" :src="cover" alt="err"/>
        </div>
        <main class="session_content">
            <Player ref="player"></Player>
            <ToggleView @play="play" @stop="stop" @update="refresh"></ToggleView>
        </main>
    </div>
</template>

<script>
    import Player from "./session/Player.vue";
    import ToggleView from "./session/ToggleView.vue";

    export default {
        name: "Session",
        components: {ToggleView, Player},
        computed: {
            id() {
                return this.$route.params.ssid;
            },

            session() {
                return this.$store.getters.session;
            },

            wrapClass() {
                return this.$route.name.includes('app-') ? 'session_wrap session_full' : 'session_wrap';
            }
        },

        data(){
            return{
                cover: '/../../img/album-covers-collage.jpg',
            }
        },

        methods: {
            refresh() {
                this.$refs.player.update();

                setTimeout(() => {
                    this.updateBackground();
                }, 500);
            },

            updateBackground() {
                this.cover = '/../../img/album-covers-collage.jpg';

                if (this.session.nowPlaying && this.session.nowPlaying.mode === 'active') {
                    this.cover = this.session.nowPlaying.song.cover;
                }
            },

            play() {
                this.$refs.player.play();
            },

            stop() {
                this.$refs.player.stop();
            },
        },

        watch: {
            session(to, from) {
                this.updateBackground();
            },

            id(to, from) {
                this.refresh();
            }
        },

        mounted() {
            this.refresh();
        },
    }
</script>
