<template>
    <div :class="isApp ? 'card card_full' : 'card'">
        <div class="card_title"><Back></Back>Friends <button class="card_title_btn" @click="$router.push({name: isApp ? 'app-friend-search' : 'friend-search'})">search</button></div>
        <div class="card_body">
            <RefreshView @refresh="refresh">
                <FriendItem :key="index" :user="friend" v-for="(friend, index) in friends"></FriendItem>
            </RefreshView>
        </div>
    </div>
</template>

<script>
    import FriendItem from '../elements/FriendItem.vue';

    import Back from "../elements/Back.vue";
    import RefreshView from "../elements/RefreshView.vue";

    export default {
        name: "Friends",

        components: {RefreshView, Back, FriendItem},

        computed: {
            friends() {
                return this.$store.getters.friends;
            },

            isApp() {
                return this.$route.name.includes('app');
            }
        },

        methods: {
            refresh(callback = null) {
                return callback ? callback(this.$store.dispatch('listFriends')) : this.$store.dispatch('listFriends');
            },
        }
    }
</script>
