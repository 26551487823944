<template>
    <div>
        <div class="toggle_page_title">Invite Friends <button class="toggle_page_title_btn" @click="hide()">done_outline</button></div>
        <div class="toggle_page_content">
            <InviteItem v-for="(friend, index) in friends" :user="friend" :ssid="session.uuid" :key="index"></InviteItem>
        </div>
    </div>
</template>

<script>
    import InviteItem from '../../elements/InviteItem.vue';
    export default {
        name: "Invite",
        props: ['hide'],
        components: {InviteItem},

        computed: {
            session() {
                return this.$store.getters.session;
            },

            friends() {
                return this.$store.getters.friends;
            },
        },

        data() {
            return {

            }
        },

        methods: {

        },

        mounted() {

        }

    }
</script>
