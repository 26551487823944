export default {
    // VARS

    screen: {
        phone_w: 620,
        phone_h: 512,
        tablet_w: 1024,
        tablet_h: 920,
        desktop_w: 1200,
        desktop_h: 1200,
    },

    // METHODS
}