<template>
    <nav :class="wrapClass">
        <div class="nav_head_wrap">
            <span class="nav_head_title">SYMPE</span>
            <div class="nav_fab_wrap">
                <button class="fab_btn" @click="$router.push({name: 'edit'})">playlist_add</button>
                <button class="fab_btn" @click="$router.push({name: 'accounts'})">person</button>
                <button class="fab_btn" @click="$router.push({name: 'timeline'})">update</button>
                <button class="fab_btn" @click="$router.push({name: 'friends'})">supervisor_account</button>
                <button class="fab_btn" @click="$router.push({name: 'settings'})">settings</button>
            </div>
        </div>
        <div class="session_list_wrap">
            <RefreshView @refresh="refresh">
                <SessionItem
                    v-for="invite in incoming"
                    :uuid="invite.session_uuid"
                    :title="invite.title"
                    :mode="invite.mode"
                    :active="invite.active"
                    :playing="invite.playing"
                    :key="invite.session_uuid"></SessionItem>
                <SessionItem
                    v-for="invite in favorites"
                    :uuid="invite.session_uuid"
                    :title="invite.title"
                    :mode="invite.mode"
                    :active="invite.active"
                    :playing="invite.playing"
                    :key="invite.session_uuid"></SessionItem>
                <NoteView v-if="isEmpty" :title="empty_note.title" :points="empty_note.points"></NoteView>
            </RefreshView>
        </div>
    </nav>
</template>

<script>
    import SessionItem from "../elements/SessionItem.vue";
    import NoteView from "../elements/NoteView.vue";
    import RefreshView from "../elements/RefreshView.vue";

    export default {
        name: "Navigation",

        components: {RefreshView, NoteView, SessionItem},

        data(){
            return{
                empty_note: {
                    title: 'Get Started',
                    points: [
                        {type: 'action', icon: 'playlist_add', title: 'Add a Session', desc: 'Create your own session', action: () => {this.$router.push({name: 'edit'})}},
                        {type: 'action', icon: 'person', title: 'Accounts', desc: 'Manage accounts and playlists', action: () => {this.$router.push({name: 'accounts'})}},
                        {type: 'action', icon: 'update', title: 'Timeline', desc: 'Songs you recently listened to', action: () => {this.$router.push({name: 'timeline'})}},
                        {type: 'action', icon: 'supervisor_account', title: 'Friends', desc: 'Find and manage friends', action: () => {this.$router.push({name: 'friends'})}},
                        {type: 'action', icon: 'settings', title: 'Settings', desc: 'Work in progress', action: () => {this.$router.push({name: 'settings'})}},
                    ]
                },
            }
        },

        computed: {
            incoming() {
                return this.$store.getters.invites_incoming;
            },

            favorites() {
                return this.$store.getters.invites_favorites;
            },

            badge() {
                return this.$store.getters.invites_badge;
            },

            isEmpty() {
                return this.favorites.length === 0 && this.incoming.length === 0;
            },

            route() {
                return this.$route.name;
            },

            wrapClass() {
                return this.route === 'app' ? 'app_nav nav_full' : 'app_nav';
            }
        },

        methods: {
            refresh(callback = null) {
                return callback ? callback(this.$store.dispatch('listInvites')) : this.$store.dispatch('listInvites');
            },

            setBadge(num) {
                if ('setAppBadge' in navigator) {
                    return navigator.setAppBadge(num);
                }
                return false;
            },
        },

        watch: {
            badge(to) {
                this.setBadge(to);
            }
        }
    }
</script>
