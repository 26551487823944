<template>
    <div class="settings_item">
        <div class="settings_item_title">{{title}}</div>
        <div class="settings_item_body">
            <div class="friend_item" v-for="(flavor, index) in flavors" :key="index">
                <span class="friend_item_name">{{flavor.title || flavor.name}}</span>
                <button class="friend_item_action" @click="selectFlavor(flavor)" :disabled="itemSelected(flavor.id)">
                    {{itemIcon(flavor.id)}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SelectFlavor",

        props: ['title'],

        data() {
            return {
                flavors: [
                    {id: 1, title: 'gaga', genre_id: 1}
                ],
                selected: [],
            }
        },

        methods: {

            selectFlavor(flavor) {
                let out = {
                    title: flavor.title,
                    ...flavor
                };

                if (!out.title) {
                    out.title = out.name;
                }

                this.selected.push(flavor.id);
                this.$emit('select', out);
            },

            itemSelected(id) {
                return this.selected.includes(id);
            },

            itemIcon(id) {
                return this.itemSelected(id) ? 'done' : 'add';
            }
        }
    }
</script>
