<template>
    <div class="playlist">
        <label class="playlist_head">
            <span class="playlist_title">{{playlist.name}}</span>
            <button class="playlist_toggle" @click="toggle">{{show ? 'expand_less' : 'expand_more'}}</button>
        </label>
        <div v-bind:class="show ? 'playlist_body playlist_body_show' : 'playlist_body'">
            <SongItem v-for="(song, index) in songs" :key="index" :song="song" @update="$emit('update')"></SongItem>
        </div>
    </div>
</template>

<script>
    import SongItem from "./SongItem.vue";

    export default {
        name: "Playlist",
        components: {SongItem},
        props: ['playlist', 'show', 'open'],

        data() {
            return {
                songs: [],
            }
        },

        methods: {
            toggle() {
                if (this.show) {
                    this.open(-1);
                } else {
                    this.open(this.$vnode.key);

                    if (this.songs.length === 0) {
                        this.getPlaylist();
                    }
                }
            },

            getPlaylist() {
                this.$store.dispatch('getPlaylist', this.playlist.id).then((data) => {
                    this.songs = data.songs;
                });
            },
        },
    }
</script>