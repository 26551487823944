<template>
    <div :class="isApp ? 'card card_full' : 'card'">
        <!--ToDo: <Loader min="true" v-if="loading"/>-->
        <b class="card_title"><Back></Back>Timeline</b>
        <div class="card_body" v-if="!loading">
            <a href="javascript:void(0)" class="double_nav_wrap" v-for="song in recent">
                <div class="double_nav">
                    <span class="double_nav_title">{{song.title}}</span>
                    <span class="double_nav_desc" v-html="description(song)"></span>
                </div>
                <span class="double_nav_arrow">keyboard_arrow_right</span>
            </a>
        </div>
    </div>
</template>

<script>
    import {format} from 'date-fns';
    import {enLocale} from 'date-fns/locale/en-US';
    import Back from "../elements/Back.vue";

    export default {
        name: "Timeline",
        components: {Back},
        computed: {
            isApp() {
                return this.$route.name.includes('app');
            }
        },

        data() {
            return {
                recent: [],

                loading: false,
            }
        },

        methods: {
            load() {
                this.loading = true;
                this.recent = [];

                this.$store.dispatch('timeline').then((data) => {
                    data.forEach(session => {
                        this.recent.push(...session.songs);
                    });

                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            },

            description(song) {
                return `${song.artists.map(artist => artist.name).join(', ')} <span class="icon_s">access_time</span> ${this.beautyDate(song.start)}`;
            },

            beautyDate(time) {
                return format(new Date(time), 'MMMM Mo yyyy, HH:ss', {locale: enLocale});
            },
        },

        created() {
            this.load();
        }
    }
</script>
