<template>
    <button type="submit" class="cta_btn" :disabled="isDisabled">
        {{showLabel}}
        <span class="icon_m cta_icon">{{showIcon}}</span>
    </button>
</template>

<script>
    import {FORM_MODE} from "../../../objects/form";

    const MODE_FEATURES = {
        [FORM_MODE.DEFAULT]: {
            label: null,
            icon: null,
            disabled: false,
        },
        [FORM_MODE.LOADING]: {
            label: 'form.loading',
            icon: 'timelapse',
            disabled: true,
        },
        [FORM_MODE.RETRY]: {
            label: 'form.retry',
            icon: 'autorenew',
            disabled: false,
        },
        [FORM_MODE.SUCCESS]: {
            label: 'form.success',
            icon: 'done',
            disabled: true,
        },
    };

    export default {
        name: "Submit",

        props: ['label', 'icon', 'mode'],

        computed: {
            isDisabled() {
                return MODE_FEATURES[this.mode].disabled;
            },
            showIcon() {
                return MODE_FEATURES[this.mode].icon || this.icon;
            },
            showLabel() {
                return this.$t((MODE_FEATURES[this.mode] && MODE_FEATURES[this.mode].label)
                    || this.label || 'form.submit');
            }
        },
    }
</script>
