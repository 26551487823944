<template>
    <Card title="Accounts">
        <DoubleNav @click="open(service)" :title="service.title" :desc="service.detail" v-for="service in services" :key="service.id"/>
    </Card>
</template>

<script>
    import spotify from "../../objects/spotify.js";

    import Back from "../elements/Back.vue";
    import DoubleNav from "../elements/DoubleNav.vue";
    import Card from "../elements/Card.vue";

    export default {
        name: "Accounts",
        components: {Card, DoubleNav, Back},
        computed: {
            accounts() {
                return this.$store.getters.accounts;
            },

            isApp() {
                return this.$route.name.includes('app');
            },
        },

        data() {
            return {
                services: [

                ],
            }
        },

        methods: {
            refresh() {
                this.$store.dispatch('listAccounts').then(() => {
                    let spotifyID = -1;

                    for (let i = 0; i < this.accounts.length; i++) {
                        if (this.accounts[i].service === 'spotify') {
                            spotifyID = i;
                        }
                    }

                    this.services = [
                        {
                            id: spotifyID,
                            title: 'Spotify',
                            type: 'spotify',
                            detail: spotifyID !== -1 ? this.accounts[spotifyID].selected_playlists + ' Playlists Selected' : 'Connect',
                            action: spotifyID !== -1 ? 'select' : 'connect',
                        },
                    ];
                });
            },



            open(service) {
                if (service.action === 'select') {
                    this.select(service.id);
                } else if (service.action === 'connect') {
                    this.connect(service);
                }
            },

            connect(service) {
                if (service.type === 'spotify') {
                    spotify.showAuth(this.$store.getters.user.id);
                    this.awaitCallback();
                }
            },

            select(id) {
                const name = this.isApp ? 'app-manage-account' : 'manage_account'

                this.$router.push({name: name, params: {'id': id}});
            },

            awaitCallback() {
                window.addEventListener('focus', this.onFocus);
            },

            onFocus() {
                this.refresh();
                window.removeEventListener('focus', this.onFocus);
            }
        },

        mounted() {
            this.refresh();
        },
    }
</script>
