<template>
    <div class="card card_onboarding">
        <div class="card_head">
            <b class="card_title">{{service.name}} <button class="card_title_btn" @click="save">save</button></b>
        </div>
        <div class="card_body">
            <label href="javascript:void(0)" class="switch_item" v-for="playlist in playlists">
                <input type="checkbox" class="switch_check_box" v-model="playlist.load">
                <span class="switch_item_title">{{playlist.name}}</span>
                <div class="switch">
                    <span class="lever"></span>
                </div>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SelectPlaylists",

        props: ['mode'],

        computed: {
            isApp() {
                return this.$route.name.includes('app');
            },
        },

        data() {
            return {
                'service': {
                    name: 'Spotify'
                },
                'playlists': [],
            };
        },

        methods: {
            save() {
                const selected = this.playlists;

                this.playlists = [];

                this.$store.dispatch('saveSpotifyPlaylists', selected).then(() => {
                    setTimeout(this.getPlaylists, 600);
                    this.$parent.done = true;
                });
            },

            getPlaylists() {
                this.$store.dispatch('listSpotifyPlaylists').then((data) => {
                    this.playlists = data;

                    this.checkPlaylists();
                });
            },

            checkPlaylists() {
                let already = false;

                for (let i = 0; i < this.playlists.length; i++) {
                    if (this.playlists[i].load) {
                        already = true;
                    }
                }

                if (already) {
                    this.$parent.done = true;
                }
            },

        },

        created() {
            this.getPlaylists();
            this.$parent.done = false;
        }

    }
</script>