<template>
    <div class="session_switch_wrap" ref="session_switch">
        <VoteView @update="$emit('update')" @menu="openMenu"/>

        <div class="session_nav" ref="nav">
            <div class="session_nav_bar"><div class="session_nav_trigger"></div></div>
            <div class="session_nav_items">
                <label class="session_nav_item" v-for="(items, index) in menu.items" @click="openMenu(index)">
                    <input type="radio" name="session_radio" class="session_radio" :checked="menu.selected === index"/>
                    <span class="session_nav_item_icon">{{items.icon}}</span>
                </label>
            </div>
        </div>

        <div class="session_menu">
            <Search v-if="menu.selected === 0" @update="$emit('update')"></Search>
            <Library v-if="menu.selected === 1" @update="$emit('update')"></Library>
            <Social v-if="menu.selected === 2"></Social>
            <Options v-if="menu.selected === 3" @play="$emit('play')" @stop="$emit('stop')" @update="$emit('update')"></Options>
        </div>
    </div>
</template>

<script>
    import setup from "../../../setup.js";
    import VoteView from "./VoteView.vue";
    import Search from "./Search.vue";
    import Library from "./Library.vue";
    import Social from "./Social.vue";
    import Options from "./Options.vue";
    import Back from "../../elements/Back.vue";

    export default {
        name: "ToggleView",
        components: {Back, VoteView, Search, Library, Social, Options},
        props: ['fav'],

        computed: {
            session() {
                return this.$store.getters.session;
            },
        },

        data() {
            return {

                menu: {
                    open: false,
                    dragged: false,
                    selected: -1,
                    yPos: 0,
                    icon: 'keyboard_arrow_up',
                    items: [
                        {icon: 'search'},
                        {icon: 'playlist_play'},
                        {icon: 'people'},
                        {icon: 'settings'},
                    ],
                },
            }
        },

        methods: {
            toggle() {
                this.menu.dragged = false;

                if (this.menu.open) {
                    this.hideMenu();
                } else {
                    this.showMenu();
                }
            },

            openMenu(id) {
                this.menu.selected = id;
                this.showMenu();
            },

            hideMenu() {
                this.$refs.session_switch.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });

                this.menu.open = false;
                this.menu.icon = 'keyboard_arrow_up';
            },

            showMenu() {
                if (this.menu.selected === -1) {
                    this.menu.selected = 0;
                }

                this.$refs.session_switch.scroll({
                    top: this.$refs.session_switch.scrollHeight,
                    left: 0,
                    behavior: 'smooth'
                });

                this.menu.open = true;
                this.menu.icon = 'keyboard_arrow_down';
            },

            dragStart(event) {
                this.menu.dragged = true;
            },

            drag(event) {
                if (this.menu.dragged) {
                    this.menu.yPos = this.getPos(event);

                    this.$refs.session_switch.scrollTop = this.$refs.session_switch.clientHeight - this.menu.yPos;
                }
            },

            drop(event) {
                if (this.menu.dragged) {

                    if (this.menu.open) {
                        if (this.$refs.session_switch.clientHeight / 8 < this.menu.yPos) {
                            this.hideMenu();
                        } else {
                            this.showMenu();
                        }
                    } else {
                        if (((this.$refs.session_switch.clientHeight / 8) * 7) > this.menu.yPos) {
                            this.showMenu();
                        } else {
                            this.hideMenu();
                        }
                    }

                    this.menu.dragged = false;
                    this.menu.yPos = 0;
                }
            },

            getPos(event) {
                return event.clientY ? (window.innerWidth < setup.screen.tablet_w || window.innerHeight < setup.screen.tablet_h ? event.clientY - 128 : event.clientY - 210) : event.touches[0].clientY - 128;
            },
        },

        mounted() {
            //DRAG N DROP
            this.$refs.nav.onmousedown = event => { this.dragStart(event) };
            this.$refs.nav.onmouseup = event => { this.drop(event) };
            this.$refs.session_switch.onmouseleave = event => { this.drop(event) };
            this.$refs.session_switch.onmousemove = event => { this.drag(event) };

            this.$refs.nav.ontouchstart = event => { this.dragStart(event) };
            this.$refs.nav.ontouchend = event => { this.drop(event) };
            this.$refs.session_switch.ontouchend = event => { this.drop(event) };
            this.$refs.session_switch.ontouchmove = event => { this.drag(event) };
        },
    }
</script>
