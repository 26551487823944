<template>
    <div class="managed_list_item_action">
        <select v-if="action.type === 'dropdown'" v-model="chosen">
            <option v-for="option in action.options" :value="option.key">{{option.value}}</option>
        </select>
        <div class="icon_s" v-if="action.type === 'icon'">{{action.icon}}</div>
    </div>
</template>

<script>
    export default {
        name: "ManagedListItemAction",
        props: ['action', 'index', 'selected'],

        data() {
            return {
                chosen: null,
            }
        },

        methods: {
            callAction(option) {
                if (this.action.changed) {
                    this.action.changed(option, this.index);
                }
            },
        },

        mounted() {
            this.chosen = this.selected;
        },

        watch: {
            chosen(to) {
                this.callAction(to);
            }
        }
    }
</script>
