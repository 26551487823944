<template>
    <div class="site_content_wrap">
        <main class="site_content">

        </main>
    </div>
</template>

<script>
    export default {
        name: "More",

        computed: {
        },

        data() {
            return {
            }
        },

        methods: {

        },
    }
</script>