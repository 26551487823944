<template>
    <div class="card card_onboarding">
        <div class="card_head">
            <b class="card_title">Accounts</b>
        </div>
        <div class="card_body">
            <RefreshView @refresh="refresh">
                <a href="javascript:void(0)" class="double_nav_wrap" @click="open(service)" v-for="service in services">
                    <div class="double_nav">
                        <span class="double_nav_title">{{service.title}}</span>
                        <span class="double_nav_desc">{{service.detail}}</span>
                    </div>
                    <span class="double_nav_arrow">keyboard_arrow_right</span>
                </a>
            </RefreshView>
        </div>
    </div>
</template>

<script>
    import spotify from "../../../objects/spotify.js";
    import RefreshView from "../../elements/RefreshView.vue";

    export default {
        name: "Accounts",
        components: {RefreshView},
        computed: {
            accounts() {
                return this.$store.getters.accounts;
            },

            isApp() {
                return this.$route.name.includes('app');
            },
        },

        data() {
            return {
                services: [

                ],
            }
        },

        methods: {
            refresh(callback = null) {
                return callback ? callback(this.loadList) : this.loadList();
            },

            loadList() {
                this.$parent.done = false;
                return this.$store.dispatch('listAccounts').then(() => {
                    let spotifyID = -1;

                    for (let i = 0; i < this.accounts.length; i++) {
                        if (this.accounts[i].service === 'spotify') {
                            spotifyID = i;
                        }
                    }

                    this.services = [
                        {
                            id: spotifyID,
                            title: 'Spotify',
                            type: 'spotify',
                            detail: spotifyID !== -1 ? 'Connected' : 'Connect',
                            action: spotifyID !== -1 ? 'select' : 'connect',
                        },
                    ];

                    this.$parent.done = spotifyID !== -1;
                });
            },

            open(service) {
                if (service.action === 'select') {

                } else if (service.action === 'connect') {
                    this.connect(service);
                }
            },

            connect(service) {
                if (service.type === 'spotify') {
                    spotify.showAuth(this.$store.getters.user.id);
                    this.awaitCallback();
                }
            },

            awaitCallback() {
                window.addEventListener('focus', this.onFocus);
            },

            onFocus() {
                this.refresh();
                window.removeEventListener('focus', this.onFocus);
            }
        },

        mounted() {
            this.refresh();
        },
    }
</script>
