<template>
    <div class="site_content_wrap">
        <main class="site_content">
            <Form
                @submit="handleSubmit"
                @mode="handleMode"
                :initial="initial"
                ref="form"
            >
                <div class="form_wrap" v-if="!isActive">
                    <TextInput
                        @change="handleChange"
                        :mode="form_mode"
                        :type="getTypes.USER"
                        label="Username"
                        name="username"
                        required="true"
                    />
                    <TextInput
                        @change="handleChange"
                        :mode="form_mode"
                        :type="getTypes.PASSWORD_UNSAFE"
                        label="Password"
                        name="password"
                        required="true"
                    />
                </div>
                <Submit :mode="form_mode" :label="getProps.title" :icon="getProps.icon"/>
            </Form>
        </main>
    </div>
</template>

<script>
    import Form from "../elements/form/Form.vue";
    import TextInput from "../elements/form/TextInput.vue";
    import Submit from "../elements/form/Submit.vue";
    import {FORM_MODE, INPUT_TYPE} from "../../objects/form";

    const LOGIN_MODE = {
        SUBMIT: 'submit',
        ENTER: 'enter'
    };

    const LOGIN_PROPS = {
        [LOGIN_MODE.SUBMIT]: {
            title: 'Login',
            icon: 'lock_open',
        },
        [LOGIN_MODE.ENTER]: {
            title: 'Get In',
            icon: 'exit_to_app',
        },
    }

    export default {
        name: "Login",
        components: {Submit, TextInput, Form},

        data() {
            return {
                initial: {
                    user: '',
                    password: '',
                },
                login_mode: LOGIN_MODE.SUBMIT,
                form_mode: FORM_MODE.DEFAULT,
            }
        },

        computed: {
            getTypes() {
                return INPUT_TYPE;
            },

            getProps() {
                return LOGIN_PROPS[this.login_mode];
            },

            isActive() {
                return (typeof (localStorage.token) !== 'undefined');
            },
        },

        methods: {
            startLook() {
                this.login_mode = this.isActive ? LOGIN_MODE.ENTER : LOGIN_MODE.SUBMIT;
            },

            handleSubmit(values) {
                if (this.isActive) {
                    this.$router.push({name: 'loader'});
                } else {
                    this.login(values);
                }
            },

            handleMode(mode) {
                this.form_mode = mode;
            },

            handleChange({name, value}) {
                this.$refs.form.setValue(name, value);
            },

            setMode(mode) {
                this.$refs.form.setMode(mode);
            },

            login(values) {
                this.$store.dispatch('login', values).then((data) => {
                    this.setMode(FORM_MODE.SUCCESS);
                    if (data.active.id) {
                        setTimeout(() => {
                            this.$router.push({name: 'loader'});
                        }, 100);
                    }
                }).catch(() => {
                    this.setMode(FORM_MODE.RETRY);
                });
            },
        },

        mounted() {
            this.startLook();
        },
    }
</script>
