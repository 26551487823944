import Vue from 'vue';
import axios from 'axios';

export default {
    scopes: [
        'streaming',
        'user-read-birthdate',
        'user-read-email',
        'user-read-private',
        'playlist-read-private',
        'playlist-read-collaborative',
        'playlist-modify-private',
        'playlist-modify-public',
        'user-modify-playback-state',
        'user-read-playback-state',
    ],

    account: {},
    props: [],
    uri: '',
    player: null,
    active: false,
    delay: {
        current: 120,
        start: -1,
        pos: -1,
    },

    init(account, props, setProp) {
        if (account.access_token) {
            this.setProp = setProp;
            this.account = account;
            this.props = props;

            this.initSDK();
        }
    },

    connect() {
        if (!this.getDevice()) {
            this.buildPlayer();
        }
    },

    initSDK() {
        let already = false;
        Array.from(document.head.children).forEach((child) => {
            if (child.nodeName === 'SCRIPT' && child.src.includes('sdk.scdn.co/spotify-player.js')) {
                already = true;
            }
        });

        if (!already) {
            let spotifySDK = document.createElement('script');
            spotifySDK.setAttribute('src', '//sdk.scdn.co/spotify-player.js');
            document.head.appendChild(spotifySDK);

            this.connect();
        }
    },

    update(account) {

    },

    putAccount(accessToken, refreshToken) {
        this.$store.dispatch('putAccount', {
            id: this.account.id,
            access_token: accessToken,
            refresh_token: refreshToken,
            service: 'spotify'
        }).then((data) => {
            //ToDo: Error
        });
    },

    improveDelay(player) {
        if (this.active && this.delay.start !== -1) {
            const tolerance = 10;
            const new_delay = new Date().getTime() - this.delay.start;
            this.delay.start = -1;

            if (this.delay.current + tolerance <= new_delay || this.delay.current - tolerance >= new_delay) {
                this.delay.current = new_delay;
                let seekTime = this.delay.pos - (new_delay * 2);

                if (seekTime >= 0) {
                    player.seek(seekTime);
                } else {
                    setTimeout(() => {
                        player.seek(0);
                    }, Math.abs(seekTime));
                }
            }
        }
    },

    showAuth(userID) {
        //ToDo: Indirect via Server

        if (userID) {
            localStorage.setItem('userID', userID);

            let redirect = window.location.protocol + '//' + window.location.host + '/callback/spotify/';
            let url = 'https://accounts.spotify.com/authorize/?client_id=addb288209274fc080874d460e4776c7&response_type=code&redirect_uri=' + redirect + '&scope=' + this.scopes.join('%20');

            window.open(url);
        }
    },

    buildPlayer() {
        window.onSpotifyWebPlaybackSDKReady = () => {
            if (this.account.access_token !== '') {
                const player = new Spotify.Player({
                    name: 'Sympe Web',
                    getOAuthToken: cb => {
                        cb(this.account.access_token);
                    }
                });

                this.player = player;

                player.on('initialization_error', e => this.deviceError(e));
                player.on('authentication_error', e => this.deviceError(e));
                player.on('account_error', e => this.deviceError(e));
                player.on('playback_error', e => this.deviceError(e));

                player.addListener('player_state_changed', state => {
                    this.active = state === null ? false : !state.paused;

                    //check is playable

                    this.improveDelay(player);
                });

                player.addListener('ready', ({device_id}) => {
                    this.setDevice(device_id);

                    this.loadDeviceList();

                    this.improvePlayer();
                });

                player.addListener('not_ready', ({device_id}) => {
                    this.setDevice('');

                    this.loadDeviceList();
                });

                player.connect();
            }
        };
    },

    improvePlayer() {
        // Fix Chrome 74.x Iframe loading policies
        const iframe = document.querySelector('iframe[src="https://sdk.scdn.co/embedded/index.html"]');

        if (iframe) {
            iframe.style.position = 'absolute';
            iframe.style.display = 'block';
            iframe.style.height = '20px';
            iframe.style.width = '20px';
            iframe.style.top = '-40px';
            iframe.style.left = '-40px';
        }
    },

    deviceError(e) {
        this.loadDeviceList().then(() => {
            if (this.getDevice() === undefined) {
                this.autoDevice();
            }
        });
    },

    autoDevice() {
        this.setDevice(this.props.devices[0].id);
    },

    setDevice(device_id) {
        this.setProp('spotify', 'device_id', device_id);
    },

    getDevice() {
        return typeof this.props === 'undefined' ? null : this.props.device_id;
    },

    //LOADERS

    play(uri, pos) {
        if(this.getDevice() !== null) {
            this.delay.start = new Date().getTime();
            this.delay.pos = pos;
            this.active = true;
            this.uri = uri;

            this.putRest('https://api.spotify.com/v1/me/player/play?device_id=' + this.getDevice(), {uris: [uri], position_ms: pos - this.delay.current});
        } else {
            setTimeout(() => {
                this.play(uri, pos);
            }, 200);
        }
    },

    stop() {
        if (this.active && this.getDevice() !== null) {
            this.putRest('https://api.spotify.com/v1/me/player/pause?device_id=' + this.getDevice(), {});

            this.uri = '';
        }
    },

    loadDeviceList() {
        return this.getRest('https://api.spotify.com/v1/me/player/devices').then(data => {
            this.setProp('spotify', 'devices', data.data.devices);
        });
    },

    getRest(url) {
        let head = {
            'Authorization': 'Bearer ' + this.account.access_token,
            'Content-Type': 'application/json'
        };

        const ax = axios.create();
        ax.defaults.headers.common = {};

        return ax.get(url, {headers: head});
    },

    putRest(url, params) {
        let head = {
            'Authorization': 'Bearer ' + this.account.access_token,
            'Content-Type': 'application/json'
        };

        const ax = axios.create();
        ax.defaults.headers.common = {};

        return ax.put(url, JSON.stringify(params), {headers: head});
    },
}