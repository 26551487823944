<template>
    <div class="card card_onboarding">
        <div class="card_body">
            <HowToView></HowToView>
            <WebApp></WebApp>
            <Loader :min="false"></Loader>
        </div>
    </div>
</template>

<script>
    import Loader from "../Loader.vue";
    import HowToView from "../../elements/HowToView.vue";
    import WebApp from "../../elements/WebApp.vue";

    export default {
        name: "Done",

        components: {WebApp, HowToView, Loader},

        computed: {
            accounts() {
                return this.$store.getters.accounts;
            },
        },

        data() {
            return {

            }
        },

        created() {
            this.$parent.done = false;
        },

        watch: {
            accounts(to) {
                if (to.length !== 0) {
                    this.$parent.done = true;
                }
            },
        }
    }
</script>
