<template>
    <div class="friend_item">
        <span class="friend_item_name">{{user.username}}</span>
        <button class="friend_item_action" @click="changeStatus">{{icon}}</button>
    </div>
</template>

<script>
    const states = {
        default: 0,
        friend: 1,
        invited: 2,
        incoming: 3,
        self: 4
    };

    const stateIcons = [
        'person_add',
        'how_to_reg',
        'contact_support',
        'mail',
        'perm_identity'
    ];

    export default {
        name: "FriendItem",
        props: ['user'],

        data() {
            return {
                state: states.default,
                icon: 'person_add'
            }
        },

        methods: {
            refresh() {
                let userID = this.$store.getters.user.id;

                if (this.user.user1 === userID || this.user.user2 === userID || this.user.is_friend) {
                    this.state = states.friend;
                } else if (this.user.id === userID) {
                    this.state = states.self;
                }

                this.icon = stateIcons[this.state];
            },

            changeStatus() {
                if (this.state !== states.self) {
                    let change = this.state === states.friend && confirm('Remove Friend?') || this.state !== states.friend;

                    if (change) {
                        this.$store.dispatch('changeFriend', this.user.id).then(() => {
                            this.$store.dispatch('listFriends');
                        });
                    }
                }
            },
        },

        mounted() {
            this.refresh();
        }
    }
</script>