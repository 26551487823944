<template>
    <div class="vote_view">
        <VoteItem v-for="(vote, index) in votes" :vote="vote" :key="index" @update="$emit('update')"></VoteItem>
        <NoteView v-if="votes.length === 0" :title="empty_note.title" :points="empty_note.points"></NoteView>
    </div>
</template>

<script>
    import VoteItem from "../../elements/VoteItem.vue";
    import NoteView from "../../elements/NoteView.vue";

    export default {
        name: "VoteView",
        components: {NoteView, VoteItem},

        data(){
            return{
                empty_note: {
                    title: this.$t('session.random.title'),
                    points: [
                        {type: 'text', text: this.$t('session.random.intro')},
                        {
                            type: 'action',
                            icon: 'playlist_play',
                            action: () => {this.$emit('menu', 1)},
                            title: this.$t('session.random.playlist.title'),
                            desc: this.$t('session.random.playlist.desc')
                        },
                        {
                            type: 'action',
                            icon: 'search',
                            action: () => {this.$emit('menu', 0)},
                            title: this.$t('session.random.search.title'),
                            desc: this.$t('session.random.search.desc')
                        },
                    ]
                },
            }
        },

        computed: {
            votes() {
                return this.$store.getters.votes.sort((a, b) => {
                    return b.score - a.score;
                });
            },

            session() {
                return this.$store.getters.session;
            }
        },

        mounted() {
        }
    }
</script>
