<template>
    <div :class="isApp ? 'card card_full' : 'card'">
        <div class="card_title"><Back></Back>{{$t('song.title')}}</div>
        <div class="card_body">

        </div>
    </div>
</template>

<script>
    import Back from "../elements/Back.vue";

    export default {
        name: "Song",
        components: {Back},
        computed: {
            isApp() {
                return this.$route.name.includes('app');
            }
        },

        data() {
            return {
            }
        },

        methods: {
            load() {

            },

            save() {

            },
        }
    }
</script>
