<template>
    <button class="back_btn close_btn" @click="back">cancel</button>
</template>

<script>
    export default {
        name: "Close",

        props: ['prev'],

        computed: {
            isApp() {
                return this.$route.name.includes('app');
            },
        },

        data() {
            return {

            }
        },

        methods: {
            back() {
                if (this.prev !== undefined) {
                    this.$router.push({name: this.prev});
                } else if (this.isApp) {
                    this.$router.push({name: 'app'});
                } else {
                    this.$router.push({name: 'webapp'});
                }
            },
        },
    }
</script>
