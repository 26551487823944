<template>
    <div class="site_content_wrap">
        <main class="site_content">
            <Form
                @submit="handleSubmit"
                @mode="handleMode"
                :initial="initial"
                ref="form"
            >
                <TextInput
                    @change="handleChange"
                    :mode="mode"
                    :type="getTypes.USER"
                    label="Username"
                    name="user"
                    required="true"
                />
                <TextInput
                    @change="handleChange"
                    :mode="mode"
                    :type="getTypes.PASSWORD_UNSAFE"
                    label="Password"
                    name="password"
                    required="true"
                />
                <Submit :mode="mode" label="form.submit" icon="assignment_turned_in"/>
            </Form>
        </main>
    </div>
</template>

<script>
    import Form from "../elements/form/Form.vue";
    import Submit from "../elements/form/Submit.vue";
    import TextInput from "../elements/form/TextInput.vue";
    import {FORM_MODE, INPUT_TYPE} from "../../objects/form";

    export default {
        name: "TestForm",
        components: {TextInput, Submit, Form},

        computed: {
            getTypes() {
                return INPUT_TYPE;
            }
        },

        data() {
            return {
                initial: {
                    test: 'Möhre',
                },
                mode: FORM_MODE.DEFAULT,
            }
        },

        methods: {
            handleSubmit(values) {
                return this.$store.dispatch('login', values).then(() => {
                    this.setMode(FORM_MODE.SUCCESS);
                }).catch(() => {
                    this.setMode(FORM_MODE.RETRY);
                });
            },

            handleMode(mode) {
                this.mode = mode;
            },

            handleChange({name, value}) {
                this.$refs.form.setValue(name, value);
            },

            setMode(mode) {
                this.$refs.form.setMode(mode);
            }
        }
    }
</script>
