<template>
    <div class="note_view_wrap">
        <div class="note_view">
            <h1 class="note_title">{{title}}</h1>
            <div class="note_point" v-for="point in points">
                <ActionButton :title="point.title" :desc="point.desc" :icon="point.icon" @click="point.action" v-if="point.type === 'action'" />
                <span class="note_point_text" v-if="point.type === 'text'">{{point.text}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import ActionButton from "./ActionButton.vue";

    export default {
        name: "NoteView",
        components: {ActionButton},
        props: ['title', 'points'],
    }
</script>
