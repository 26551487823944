<template>
    <Card title="Setup" prev="settings">
        <WebApp />
    </Card>
</template>

<script>
    import WebApp from "../../elements/WebApp.vue";
    import Card from "../../elements/Card.vue";

    export default {
        name: "Setup",

        components: {Card, WebApp},
    }
</script>
