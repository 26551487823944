<template>
    <div :class="min ? '' : 'site_content_wrap'">
        <main :class="min ? '' : 'site_content'">
            <div :class="min ? 'loader_wrap_min' : 'loader_wrap'" ref="wrap">
                <div class="loader_slides">
                    <div class="slide_spin"></div>
                    <div class="slide_spin_small"></div>
                </div>
                <div class="loader" v-if="!min">
                    <div class="loader_progress" ref="progress"></div>
                    <div class="loader_text">{{progress.percent}} %</div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    const INSTANCES = [
        'listInvites',
        'listPlaylists',
        'listFriends',
        //'updateSpotifyPlaylists'
    ];

    export default {
        name: "Loader",

        props: ['min'],

        data() {
            return {
                progress: {
                    now: 0,
                    percent: 0
                },
                last_update: -1,
                started: false,
            }
        },

        methods: {
            start() {
                //ToDo: Check if token is valid

                if (localStorage.token !== null && !this.started) {
                    this.started = true;

                    if (this.min) {
                        this.$refs.wrap.style.opacity = 0.9;
                    }

                    this.$store.dispatch('refreshSelf', {'token': localStorage.token}).then((data) => {
                        this.addProgress();

                        INSTANCES.forEach(instance => {
                            this.$store.dispatch(instance).then(() => {
                                this.addProgress();
                            });
                        });

                        if (this.min) {
                            this.$refs.wrap.style.opacity = 0;
                        }

                        this.$store.dispatch('listAccounts').then((data) => {
                            if (data.length === 0) {
                                this.$router.push({name: 'onboarding'});
                            }

                            this.addProgress();
                        });

                        this.last_update = new Date().toLocaleTimeString();
                    });
                }
            },

            addProgress() {
                const max = INSTANCES.length + 1;
                this.progress.now++;

                this.progress.percent = Math.floor((this.progress.now / max) * 100);

                if (!this.min) {
                    this.$refs.progress.style.width = this.progress.percent + '%';
                }

                if (this.progress.now >= max) {
                    if(this.$route.name !== 'onboarding' && !this.min) {
                        this.$router.push({name: 'webapp'});
                    }
                }
            },
        },

        mounted() {
            this.start();
        },
    }
</script>
