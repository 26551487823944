<template>
    <div :class="isApp ? 'card card_full' : 'card'">
        <div class="card_title">
            <Back></Back>
            {{$t('session.managed.upgrade.title')}}
        </div>
        <div class="card_body">
            <br>
            <span>{{$t('session.managed.upgrade.summary', {name: session.title})}}</span>
            <br>
            <ActionButton
                :title="$t('session.managed.upgrade.infinite.title')"
                :desc="$t('session.managed.upgrade.infinite.desc')"
                icon="arrow_circle_up"
                @click="saveFull"/>
            <br>
            <ActionButton
                :title="$t('session.managed.upgrade.monthly.title')"
                :desc="$t('session.managed.upgrade.monthly.desc')"
                icon="schedule"
                @click="saveMonthly"/>
        </div>
    </div>
</template>

<script>
    import Back from "../elements/Back.vue";
    import ActionButton from "../elements/ActionButton.vue";

    export default {
        name: "Upgrade",
        components: {Back, ActionButton},
        computed: {
            isApp() {
                return this.$route.name.includes('app');
            },

            id() {
                return this.$route.params.ssid;
            },

            session() {
                return this.$store.getters.session;
            },

            authorized() {
                return this.session.setup.manager === 'owner';
            }
        },

        data() {
            return {

                loading: false,
            }
        },

        methods: {
            load() {
                this.loading = true;

                return this.$store.dispatch('joinSession', this.id).then(() => {

                    this.loading = false;
                }).catch(err => {

                    this.loading = false;
                });
            },

            saveFull() {
                if(!this.authorized) {
                    return false;
                }

            },

            saveMonthly() {
                if(!this.authorized) {
                    return false;
                }

            },
        },

        mounted() {
            this.load();
        }
    }
</script>
