<template>
    <iframe class="browse" :src="current_url"></iframe>
</template>

<script>
    export default {
        name: "Browse",

        computed: {
            action() {
                return this.$route.params.action;
            },
        },

        data() {
            return {
                current_url: '',
                last_route: '',
            }
        },

        methods: {
            open(url) {
                this.current_url = url;
            }
        },

        mounted() {
            if (typeof this.action === 'undefined') {
                this.open(this.$route.params.url);
            } else if (this.action === 'ticket') {
                this.open(window.location.protocol + '//' + window.location.hostname + '/ticket?token=' + localStorage.token);
            }

            this.last_route = this.$route.params.route;
        },
    }
</script>