<template>
    <div class="song_item_wrap">
        <div class="song_item">
            <img class="song_item_cover" :src="song.cover"/>
            <span class="song_item_title">{{song.title}}</span>
            <span class="song_item_artists">{{listArtists()}}</span>
        </div>
        <button class="song_add" @click="vote">{{icon}}</button>
    </div>
</template>

<script>
    export default {
        name: "SongItem",
        props: ['song'],

        computed: {
            session() {
                return this.$store.getters.session;
            }
        },

        data() {
            return {
                icon: 'add'
            }
        },

        methods: {
            vote() {
                this.$store.dispatch('upVote', {'ssid': this.session.id, 'songID': this.song.id}).then((data) => {
                    this.icon = 'thumb_up';

                    this.$emit('update');
                }).catch((error) => {
                    this.icon = 'warning';
                });
            },

            listArtists() {
                let artists = '';

                for (let i = 0; i < this.song.artists.length; i++) {
                    if (i !== 0) {
                        artists += ',';
                    }

                    artists += ' ' + this.song.artists[i].name;
                }

                return artists;
            },
        },

        watch: {
            session(to, from) {
                if (to.id !== from.id) {
                    this.icon = 'add';
                }
            }
        },
    }
</script>