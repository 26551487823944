<template>
    <div :class="isApp ? 'card card_full' : 'card'">
        <div class="card_title"><Back></Back>Create Session <button class="card_title_btn" @click="save">save</button> </div>
        <div class="card_body">
            <span class="label">{{$t('session.session_title')}}</span>
            <input type="text" placeholder="Title" v-model="session.title"/>
            <span class="label">{{$t('session.managed.title')}}</span>
            <label href="javascript:void(0)" class="switch_item">
                <input type="checkbox" class="switch_check_box" v-model="session.managed">
                <div class="switch_item_icon">admin_panel_settings</div>
                <div class="switch_item_text">
                    <span class="switch_item_title">{{$t('session.managed.title')}}</span>
                    <span class="switch_item_desc">{{$t('session.managed.desc')}}</span>
                </div>
                <div class="switch">
                    <span class="lever"></span>
                </div>
            </label>

            <a href="javascript:void(0)" class="double_nav_wrap" v-if="false">
                <div class="double_nav">
                    <span class="double_nav_title">{{$t('matcher.title')}}</span>
                    <span class="double_nav_desc">{{$t('matcher.desc')}}</span>
                </div>
                <span class="double_nav_arrow">keyboard_arrow_right</span>
            </a>
        </div>
    </div>
</template>

<script>
    import Back from "../elements/Back.vue";

    export default {
        name: "Edit",
        components: {Back},
        computed: {
            isApp() {
                return this.$route.name.includes('app');
            }
        },

        data() {
            return {
                session: {
                    title: '',
                    managed: false,
                    isPrivate: false,
                },
            }
        },

        methods: {
            load() {

            },

            save() {
                this.$store.dispatch('postSession', this.session).then((data) => {
                    if (this.session.managed) {
                        this.$router.push({name: 'upgrade', params: {ssid: data.id}});
                    }

                    this.$store.dispatch('listInvites');
                });
            },
        }
    }
</script>
