<template>
    <div class="site_content_wrap onboarding">
        <main class="site_content">
            <Accounts v-if="step_now === 0"></Accounts>
            <ManageAccount v-if="step_now === 1"></ManageAccount>
            <Done v-if="step_now === 2"></Done>
        </main>
        <div class="onboarding_steps_wrap">
            <div class="onboarding_steps">
                <div :class="className(step)" @click="navigate(step.id)" v-for="step in steps" :key="step.id">
                    <div class="onboarding_step_title">{{step.name}}</div>
                    <div class="onboarding_step_tile"></div>
                </div>
                <button class="fab_btn" @click="next" :disabled="!done">keyboard_arrow_right</button>
            </div>
        </div>
    </div>
</template>

<script>
    import ManageAccount from "./onbording/ManageAccount.vue";
    import Accounts from "./onbording/Accounts.vue";
    import Done from "./onbording/Done.vue";

    export default {
        name: "Onboarding",
        components: {Done, Accounts, ManageAccount},
        computed: {

        },

        data() {
            return {
                step_now: 0,
                done: true,

                steps: [
                    {id: 0, name: 'Connect Accounts', large: true},
                    {id: 1, name: 'Choose Favorite Playlists', large: true},
                    {id: 2, name: 'Done', large: false},
                ],
            }
        },

        methods: {
            className(step) {
                let classes = ['onboarding_step'];

                if (step.id === this.step_now) {
                    classes.push('active');
                } else if (step.id < this.step_now) {
                    classes.push('passed');
                }

                if (!step.large) {
                    classes.push('small');
                }

                return classes.join(' ');
            },

            next() {
                if (this.step_now < this.steps.length -1) {
                    this.step_now++;
                } else {
                    this.$router.push({name: 'webapp'});
                }
            },

            navigate(id) {
                if (this.step_now > id) {
                    this.step_now = id;
                }
            }
        },
    }
</script>