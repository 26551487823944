<template>
    <button class="action_button" @click="$emit('click')">
        <div class="action_button_icon" v-if="!loading">
            {{success ? 'done_all' : icon}}
        </div>
        <!--ToDo: <Loader v-if="loading"/>-->
        <div class="action_button_text">
            <span class="action_button_title">{{title}}</span>
            <span class="action_button_desc">{{desc}}</span>
        </div>
    </button>
</template>

<script>

    export default {
        name: "ActionButton",
        components: {},
        props: ['title', 'desc', 'icon', 'loading', 'success']
    }
</script>
