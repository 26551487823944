<template>
    <div class="app_container">
        <Navigation v-if="route === 'app'"/>

        <router-view v-if="route !== 'app'"></router-view>

        <Loader min="true" v-if="accounts.length === 0"></Loader>
    </div>
</template>

<script>
    import setup from "../setup.js";
    import Loader from "./screens_site/Loader.vue";
    import Navigation from "./screens_main/Navigation.vue";

    export default {
        name: "App",

        components: {Navigation, Loader},

        computed: {
            accounts() {
                return this.$store.getters.accounts;
            },

            route() {
                return this.$route.name;
            },
        },

        methods: {

            watchSize() {
                const height = window.innerHeight;
                const width = window.innerWidth;

                if (width > setup.screen.phone_w && height > setup.screen.phone_h) {
                    const name = this.$route.name === 'app' ? 'webapp' : this.$route.name.replace('app-', '');

                    this.$router.push({name: name, params: this.$route.params});
                }
            }

        },

        created() {
            this.watchSize();
            window.addEventListener("resize", this.watchSize);
        },

        destroyed() {
            window.removeEventListener("resize", this.watchSize);
        }
    }
</script>
