import spotify from "./spotify.js";

export default {
    states: {
        play: 0,
        stop: 1,
        sleep: 2
    },

    accounts: [],
    account_props: [],
    loaded: false,

    init(accounts, account_props, setProp) {
        this.setProp = setProp;
        this.accounts = accounts;
        this.account_props = account_props;

        for (let i = 0; i < this.accounts.length; i++) {
            if (this.accounts[i].service === 'spotify') {
                spotify.init(this.accounts[i], this.account_props[i], this.setProp);
            }
        }

        this.loaded = true;
    },

    playSong(song, pos, callback = null) {
        if (this.loaded) {
            if (song.sources[0].service === 'spotify') {
                spotify.play(song.sources[0].src, pos);
                if (callback) {
                    callback();
                }
            }
        } else {
            setTimeout(() => {
                this.playSong(song, pos, callback);
            }, 200);
        }
    },

    stopSong() {
        spotify.stop();
    },

    calcPos(startTime, serverTime, localTime) {
        const serverPos = serverTime - startTime;
        const localPos = new Date().getTime() - localTime;

        return serverPos + localPos;
    },
}
