<template>
    <div class="settings_item">
        <div class="settings_item_title">{{title}}</div>
        <div class="settings_item_body">
            <div class="friend_item" v-for="(user, index) in users" :key="index">
                <span class="friend_item_name">{{user.username}}</span>
                <button class="friend_item_action" @click="selectUser(user)" :disabled="itemSelected(user.id)">{{itemIcon(user.id)}}</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SelectUser",

        props: ['title', 'users'],

        data() {
            return {
                selected: []
            }
        },

        methods: {

            selectUser(user) {
                this.selected.push(user.id);
                this.$emit('select', {
                    title: user.username,
                    user_id: user.id
                });
            },

            itemSelected(id) {
                return  this.selected.includes(id);
            },

            itemIcon(id) {
                return this.itemSelected(id) ? 'done' : 'add';
            }
        }
    }
</script>
