<template>
    <div class="site_content_wrap">
        <main class="site_content">
            <h1 class="site_headline">Coming Soon</h1>
            <h3 class="site_subheadline">We will let You know when time has come</h3>
        </main>
    </div>
</template>

<script>
    export default {
        name: "Join"
    }
</script>