<template>
    <div class="settings_item managed_list_wrap">
        <div class="settings_item_title managed_list_head">
            <div class="managed_list_title">{{this.title}}</div>
            <button class="icon_btn right" @click="$emit('add')">add</button>
        </div>
        <slot></slot>
        <div class="list manage_list_body">
            <ManagedListItem v-for="(item, index) in list" :item="item" :index="index" :key="index"/>
        </div>
    </div>
</template>

<script>
    import ManagedListItem from "./ManagedListItem.vue";

    export default {
        name: "ManagedList",

        components: {ManagedListItem},

        props: ['title', 'list']
    }
</script>
