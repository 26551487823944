<template>
    <ActionButton
        @click="run"
        icon="add_to_home_screen"
        :title="$t('homescreen.title')"
        :desc="$t('homescreen.desc')"
        v-if="is_prompt"
    />
</template>

<script>
    import ActionButton from "./ActionButton.vue";

    export default {
        name: "WebApp",

        components: {ActionButton},

        data() {
            return {
                is_prompt: false,
            }
        },

        methods: {
            run() {
                if (this.hasPrompt()) {
                    window.installPrompt.prompt();

                    window.installPrompt.userChoice.then((choiceResult) => {
                        if (choiceResult.outcome === 'accepted') {
                            console.log('User accepted the A2HS prompt', choiceResult);
                        } else {
                            console.log('User dismissed the A2HS prompt', choiceResult);
                        }
                        window.installPrompt = null;
                    });
                }
            },

            hasPrompt() {
                return 'installPrompt' in window;
            }
        },

        mounted() {
            this.is_prompt = this.hasPrompt();
        },

        created() {
            this.is_prompt = this.hasPrompt();
        }
    }
</script>
