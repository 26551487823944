<template>
    <div class="loader_wrap_dyn" :style="wrapStyle">
        <div class="slide_spin"></div>
        <div class="slide_spin_small"></div>
    </div>
</template>

<script>
    export default {
        name: "Load",

        props: ['size'],

        computed: {
            wrapStyle() {
                return `height: ${this.size}px; width: ${this.size}px;`;
            }
        }
    }
</script>
