<template>
    <div class="list_item managed_list_item">
        <label class="managed_list_item_title">{{item.title}}</label>
        <ManagedListItemAction v-for="action in item.actions" :action="action" :index="index" :selected="item[action.key]" :key="index" />
        <button class="icon_btn" @click="remove" v-if="item.remove">remove</button>
    </div>
</template>

<script>
    import ManagedListItemAction from "./ManagedListItemAction.vue";

    export default {
        name: "ManagedListItem",
        components: {ManagedListItemAction},
        props: ['item', 'index'],

        methods: {

            remove() {
                this.item.remove(this.index);
            }
        }
    }
</script>
