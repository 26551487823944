import axios from 'axios';

const base = window.location.protocol + '//' + window.location.hostname + (window.location.port !== '' ? ':' + window.location.port : '');

export default {

    // --- AUTH ---
    login: ({commit}, {username, password}) => {
        return new Promise((resolve, reject) => {
            axios.post(base + '/api/user/', {username, password}).then(({data}) => {
                commit('setUser', data.user);
                commit('setActive', data.active);
                localStorage.setItem('token', data.active.token);
                resolve(data);
            }).catch(err => reject(err));
        });
    },

    claimTicket: ({commit}, {code, data}) => {
        return new Promise((resolve, reject) => {
            axios.post(base + '/api/ticket/' + code, data).then(({data}) => {
                resolve(data);
            });
        });
    },

    // --- USER ---
    refreshSelf: ({commit}, token) => {
        return new Promise((resolve, reject) => {
            axios.post(base + '/api/refresh/', token).then(({data}) => {
                if (data.error) {
                    // ToDo: Handle invalid user
                }

                commit('setUser', data.user);
                commit('setActive', data.active);
                resolve(data);
            });
        });
    },

    getUser: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            axios.get(base + '/api/user/', {params: {'userID': id}}).then(({data}) => {
                commit('setUser', data);
                resolve(data);
            });
        });
    },

    // --- INVITES ---
    listInvites: ({commit}) => {
        return new Promise((resolve, reject) => {
            axios.get(base + '/api/invites/').then(({data}) => {
                commit('setInvites', data);
                resolve(data);
            });
        });
    },

    sendInvite: ({commit}, {invitedID, ssid}) => {
        return new Promise((resolve, reject) => {
            axios.put(base + '/api/invite/', {'invitedID': invitedID, 'ssid': ssid}).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    // --- SESSION ---
    postSession: ({commit}, session) => {
        return new Promise((resolve, reject) => {
            axios.post(base + '/api/session/', session).then(({data}) => {
                resolve(data);
            });
        });
    },

    joinSession: ({commit}, ssid) => {
        return new Promise((resolve, reject) => {
            axios.get(base + '/api/session/' + ssid).then(({data}) => {
                let session = data.session;
                session.nowPlaying = data.nowPlaying;
                session.active = data.active;
                session.skips = data.skips;
                session.setup = data.setup;

                commit('setSession', session);

                commit('setVotes', data.votes);

                resolve(session);
            });
        });
    },

    playSession: ({commit}, ssid) => {
        return new Promise((resolve, reject) => {
            axios.post(base + '/api/session/' + ssid + '/play').then(({data}) => {
                resolve(data);
            });
        });
    },

    skipSession: ({commit}, ssid) => {
        return new Promise((resolve, reject) => {
            axios.put(base + '/api/session/' + ssid + '/skip').then(({data}) => {
                resolve(data);
            });
        });
    },

    upVote: ({commit}, {ssid, songID}) => {
        return new Promise((resolve, reject) => {
            axios.post(base + '/api/vote/' + ssid + '/up', {'songID': songID}).then(({data}) => {
                resolve(data);
            });
        });
    },

    downVote: ({commit}, {ssid, songID}) => {
        return new Promise((resolve, reject) => {
            axios.post(base + '/api/vote/' + ssid + '/down', {'songID': songID}).then(({data}) => {
                resolve(data);
            });
        });
    },

    searchSongs: ({commit}, query) => {
        return new Promise((resolve, reject) => {
            axios.get(base + '/api/song', {params: {'query': query}}).then(({data}) => {
                resolve(data);
            });
        });
    },

    getSessionOptions: ({commit}, {ssid}) => {
        return new Promise((resolve, reject) => {
            axios.get(base + '/api/session/' + ssid + '/options').then(({data}) => {
                resolve(data);
            });
        });
    },

    setSessionOptions: ({commit}, {ssid, options}) => {
        return new Promise((resolve, reject) => {
            axios.post(base + '/api/session/' + ssid + '/options', options).then(({data}) => {
                resolve(data);
            });
        });
    },

    // --- ACCOUNTS ---
    listAccounts: ({commit}) => {
        return new Promise((resolve, reject) => {
            axios.get(base + '/api/accounts/').then(({data}) => {
                commit('setAccounts', data);
                resolve(data);
            });
        });
    },

    putAccount: ({commit}, account) => {
        return new Promise((resolve, reject) => {
            axios.put(base + '/api/accounts/', account).then(({data}) => {
                resolve(data);
            });
        });
    },

    // --- PLAYLISTS ---
    listPlaylists: ({commit}) => {
        return new Promise((resolve, reject) => {
            axios.get(base + '/api/playlists/').then(({data}) => {
                commit('setPlaylists', data);
                resolve(data);
            });
        });
    },

    getPlaylist: ({commit}, id) => {
        return  new Promise((resolve, reject) => {
            axios.get(base + '/api/playlist/', {params: {'playlistID': id}}).then(({data}) => {
                commit('setPlaylists', data);
                resolve(data);
            });
        });
    },

    // --- SPOTIFY ---
    listSpotifyPlaylists: ({commit}) => {
        return new Promise((resolve, reject) => {
            axios.get(base + '/api/spotify/playlists').then(({data}) => {
                resolve(data);
            });
        });
    },

    saveSpotifyPlaylists: ({commit}, playlists) => {
        return new Promise((resolve, reject) => {
            axios.post(base + '/api/spotify/playlists', playlists).then(({data}) => {
                resolve(data);
            });
        });
    },

    updateSpotifyPlaylists: ({commit}) => {
        return new Promise((resolve) => {
            axios.put(base + '/api/spotify/playlists').then(({data}) => {
                resolve(data);
            });
        });
    },

    // --- FRIENDS ---
    listFriends: ({commit}) => {
        return new Promise((resolve, reject) => {
            axios.get(base + '/api/friends/').then(({data}) => {
                commit('setFriends', data);
                resolve(data);
            });
        });
    },

    findFriends: ({commit}, search) => {
        return new Promise((resolve, reject) => {
            axios.post(base + '/api/friends/find', {'s': search}).then(({data}) => {
                resolve(data);
            });
        });
    },

    changeFriend: ({commit}, friendID) => {
        return new Promise((resolve, reject) => {
            axios.post(base + '/api/friends/change', {friend: friendID}).then(({data}) => {
                resolve(data);
            });
        });
    },

    timeline: () => {
        return new Promise((resolve, reject) => {
            axios.get(base + '/api/timeline').then(({data}) => {
                resolve(data);
            });
        });
    },

}
