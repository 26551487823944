import VueRouter from 'vue-router';


const routes = [
    {
        name: 'website',
        path: '/',
        redirect: '/login',
        component: require('@/Site.vue').default,
        meta: {needsAuth: false},
        children: [
            {
                name: 'join',
                path: '/join',
                component: require('@s/Join.vue').default,
                meta: {needsAuth: false},
            },
            {
                name: 'activate',
                path: '/activate/:code',
                component: require('@s/Activate.vue').default,
                meta: {needsAuth: false},
            },
            {
                name: 'how',
                path: '/how',
                component: require('@s/How.vue').default,
                meta: {needsAuth: false},
            },
            {
                name: 'form',
                path: '/form',
                component: require('@s/TestForm.vue').default,
                meta: {needsAuth: false},
            },
            {
                name: 'login',
                path: '/login',
                component: require('@s/Login.vue').default,
                meta: {needsAuth: false},
            },
            {
                name: 'about',
                path: '/about',
                component: require('@s/About.vue').default,
                meta: {needsAuth: false},
            },
            {
                name: 'details',
                path: '/details',
                component: require('@s/More.vue').default,
                meta: {needsAuth: false},
            },
            {
                name: 'onboarding',
                path: '/onboarding',
                component: require('@s/Onboarding.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'loader',
                path: '/load',
                component: require('@s/Loader.vue').default,
                props: {min: false},
                meta: {needsAuth: true},
            },
        ],
    },
    {
        name: 'webapp',
        path: '/main/',
        component: require('@/Main.vue').default,
        meta: {needsAuth: true},
        children: [
            {
                name: 'edit',
                path: '/main/edit',
                component: require('@ms/Edit.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'timeline',
                path: '/main/timeline',
                component: require('@ms/Timeline.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'accounts',
                path: '/main/accounts',
                component: require('@ms/Accounts.vue').default,
                props: {mode: 'default'},
                meta: {needsAuth: true},
            },
            {
                name: 'manage_account',
                path: '/main/account/:id',
                component: require('@ms/various/ManageAccount.vue').default,
                props: {mode: 'default'},
                meta: {needsAuth: true},
            },
            {
                name: 'friends',
                path: '/main/friends',
                component: require('@ms/Friends.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'friend-search',
                path: '/main/friends/find',
                component: require('@ms/various/FriendSearch.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'settings',
                path: '/main/settings',
                component: require('@ms/Settings.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'setup',
                path: '/main/settings/setup',
                component: require('@ms/setting/Setup.vue').default,
                meta: {needsAuth: true}
            },
            {
                name: 'browse',
                path: '/main/browse',
                component: require('@ms/Browse.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'browse-act',
                path: '/main/browse/:action',
                component: require('@ms/Browse.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'session',
                path: '/main/:ssid',
                component: require('@ms/Session.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'upgrade',
                path: '/main/:ssid/upgrade',
                component: require('@ms/Upgrade.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'song',
                path: '/main/song',
                component: require('@ms/Song.vue').default,
                meta: {needsAuth: true},
            },
        ],
    },
    {
        name: 'app',
        path: '/app',
        component: require('@/App.vue').default,
        children: [
            {
                name: 'app-edit',
                path: '/app/edit',
                component: require('@ms/Edit.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'app-timeline',
                path: '/app/timeline',
                component: require('@ms/Timeline.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'app-accounts',
                path: '/app/accounts',
                component: require('@ms/Accounts.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'app-manage-account',
                path: '/app/account/:id',
                component: require('@ms/various/ManageAccount.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'app-friends',
                path: '/app/friends',
                component: require('@ms/Friends.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'app-friend-search',
                path: '/app/friends/find',
                component: require('@ms/various/FriendSearch.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'app-settings',
                path: '/app/settings',
                component: require('@ms/Settings.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'app-setup',
                path: '/app/settings/setup',
                component: require('@ms/setting/Setup.vue').default,
                meta: {needsAuth: true}
            },
            {
                name: 'app-browse',
                path: '/app/browse',
                component: require('@ms/Browse.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'app-browse-act',
                path: '/app/browse/:action',
                component: require('@ms/Browse.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'app-session',
                path: '/app/:ssid',
                component: require('@ms/Session.vue').default,
                meta: {needsAuth: true},
            },
            {
                name: 'app-upgrade',
                path: '/app/:ssid/upgrade',
                component: require('@ms/Upgrade.vue').default,
                meta: {needsAuth: true},
            },
        ],
        meta: {needsAuth: true},
    },
];

const router = new VueRouter({
    routes,
    base: '/',
});

router.beforeEach((to, from, next) => {
    const token = localStorage.token;

    if(to.meta.needsAuth && (token == null || token === 'null')) {
        next('/');
    }else{
        next();
    }
});

export default router;
