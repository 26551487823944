<template>
    <div :class="isApp ? 'card card_full' : 'card'">
        <b class="card_title">
            <Back :prev="prev"></Back>
            {{title}}
        </b>
        <div class="card_body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import Back from "./Back.vue";

    export default {
        name: "Card",
        components: {Back},
        props: ['title', 'prev'],
        computed: {
            isApp() {
                return this.$route.name.includes('app');
            }
        },
    }
</script>
