<template>
    <div class="vote_item">
        <button class="vote_item_thumb vote_item_thumb_up" @click="upVote">thumb_up<span class="vote_item_thumb_count">{{vote.upvotes}}</span></button>
        <div class="vote_item_info">
            <span class="vote_item_title">{{vote.song.title}}</span>
            <span class="vote_item_artists">{{listArtists()}}</span>
        </div>
        <button class="vote_item_thumb vote_item_thumb_down" @click="downVote">thumb_down<span class="vote_item_thumb_count">{{vote.downvotes}}</span></button>
    </div>
</template>

<script>
    export default {
        name: "VoteItem",
        props: ['vote'],

        methods: {
            upVote() {
                this.$store.dispatch('upVote', {'ssid': this.$store.getters.session.id, 'songID': this.vote.song.id}).then((data) => {
                    this.$emit('update');
                });
            },
            downVote() {
                this.$store.dispatch('downVote', {'ssid': this.$store.getters.session.id, 'songID': this.vote.song.id}).then((data) => {
                    this.$emit('update');
                });
            },

            listArtists() {
                let artists = '';

                for (let i = 0; i < this.vote.song.artists.length; i++) {
                    if (i !== 0) {
                        artists += ',';
                    }

                    artists += ' ' + this.vote.song.artists[i].name;
                }

                return artists;
            },
        }
    }
</script>