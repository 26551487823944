export default {
    user: {},
    active: {},
    session: {},
    votes: [],
    invites: {
        badge: 0,
        incoming: [],
        favorites: [],
    },
    accounts: [],
    account_props: [],
    playlists: [],
    friends: [],
};
