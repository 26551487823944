export default {
    setUser(state, user) {
        state.user = user;
    },
    setActive(state, active) {
        state.active = active;
    },
    setSession(state, session) {
        state.session = session;
    },
    setVotes(state, votes) {
        state.votes = votes;
    },
    setInvites(state, invites) {
        const draft = {
            badge: 0,
            incoming: [],
            favorites: [],
        };

        invites.forEach(invite => {
            if (invite.mode === 'favorite' || invite.mode === 'own') {
                draft.favorites.push(invite);
                draft.badge++;
            } else {
                draft.incoming.push(invite);
            }
        });

        state.invites = draft;
    },
    setAccounts(state, accounts) {
        state.accounts = accounts;
    },
    setAccountProp(state, prop) {
        for (let i = 0; i < state.accounts.length; i++) {
            if (state.accounts[i].service === prop.service) {
                if (typeof state.account_props[i] === 'undefined') {
                    state.account_props[i] = {};
                    state.account_props[i][prop.type] = prop.payload;
                } else {
                    state.account_props[i][prop.type] = prop.payload;
                }
                break;
            }
        }
    },
    setPlaylists(state, playlists) {
        state.playlists = playlists;
    },
    setFriends(state, friends) {
        state.friends = friends;
    },
}
