<template>
    <button class="double_nav_wrap" @click="$emit('click')">
        <div class="double_nav">
            <span class="double_nav_title">{{title}}</span>
            <span class="double_nav_desc">{{desc}}</span>
        </div>
        <span class="double_nav_arrow">keyboard_arrow_right</span>
    </button>
</template>

<script>
    export default {
        name: "DoubleNav",
        props: ['title', 'desc']
    }
</script>
