<template>
    <div class="session_head">
        <Close/>
        <button class="play_btn" ref="play_btn" @click="togglePlay">{{current_state === 0 ? 'stop' : 'play_arrow'}}</button>
        <div class="play_center">
            <div class="play_txt song_title">{{current_song.title}}</div>
            <div class="play_txt song_artist">{{listArtists()}}</div>
            <div class="play_txt session_title">{{session.title}}</div>
        </div>
        <label class="skip_label">
            <div class="skip_progress"><div class="skip_bar" :style="'height: ' + ((skips.percent < 100) ? skips.percent : 100) + '%'"></div></div>
            <button class="skip_btn" @click.stop="skip()">skip_next</button>
        </label>
        <audio ref="vanilla_player" loop autoplay :src="silence" @canplaythrough="initMediaSession"></audio>
    </div>
</template>

<script>
    import player from '../../../objects/player.js';
    import Close from "../../elements/Close.vue";

    const states = {
        play: 0,
        stop: 1,
        sleep: 2
    };

    const empty = {
        id: -1,
        title: 'Empty Session',
        artist: '',
        cover: './img/logo-cover.png',
    };

    export default {
        name: "Player",
        components: {Close},
        computed: {
            ssid() {
                return this.$route.params.ssid;
            },

            accounts() {
                return this.$store.getters.accounts;
            },

            account_props() {
                return this.$store.getters.account_props;
            },

            session() {
                return this.$store.getters.session;
            }
        },

        data() {
            return {
                current_state: states.stop,
                current_song: empty,
                skips: {
                    percent: 35
                },
                next_song: -1,
                next_pulse: null,
                silence: './audio/silence.mp3',
                wakelock: null,
            }
        },

        methods: {
            update() {
                this.pulse();
                const curr_song = this.current_song.id;

                this.updateSession().then(() => {
                    this.skips = this.session.skips;

                    if (this.session && this.session.nowPlaying) {
                        if (this.session.nowPlaying.mode === 'passive') {
                            this.current_song = empty;

                        } else if (this.session.nowPlaying.mode === 'active') {
                            this.current_song = this.session.nowPlaying.song;
                            this.checkAccounts(this.session.nowPlaying.nowTime);
                        }
                    }
                    this.updateMediaSession();

                    if (this.current_state === states.play && (this.next_song <= new Date().getTime() || curr_song !== this.current_song.id)) {
                        this.requestPlay();
                    }

                    this.setCover(this.current_song.cover);
                });
            },

            play() {
                if (this.current_state !== states.play) {
                    this.requestPlay();
                    //ToDo: await this.requestWakeLock();
                    navigator.mediaSession.playbackState = 'playing';
                }
            },

            stop() {
                if (this.current_state !== states.stop) {
                    this.current_state = states.stop;

                    player.stopSong();
                    navigator.mediaSession.playbackState = 'paused';

                    if (this.wakelock) {
                        this.wakelock.release();
                        this.wakelock = null;
                    }

                    this.pulse();
                }
            },

            skip() {
                this.$store.dispatch('skipSession', this.session.id).then((data) => {
                    this.skips = data;

                    setTimeout(this.update, 400);
                });
            },

            requestPlay() {
                this.$store.dispatch('playSession', this.session.id).then((data) => {
                    const loaded = new Date().getTime();

                    this.current_song = data.song;
                    this.current_state = states.play;
                    this.updateMediaSession();

                    let pos = player.calcPos(data.startTime, data.nowTime, loaded);
                    player.playSong(data.song, pos, () => {
                        this.updateMediaSession();
                    });

                    let time_left = data.song.duration - pos;
                    this.next_song = loaded + time_left;

                    this.pulse();

                    this.updateSession().then((session) => {
                        if (session.nowPlaying.mode === 'active') {
                                this.current_song = session.nowPlaying.song;
                        }

                        this.setCover(session.nowPlaying.song.cover);
                        this.updateMediaSession();
                    });
                });
            },

            pulse() {
                if (this.next_pulse !== null) { //ToDo: What if the last pulse was milliseconds ago??
                    clearTimeout(this.next_pulse);
                    this.next_pulse = null;
                }

                if (this.current_state === states.play) {
                    this.next_pulse = setTimeout(this.update, 6000);
                } else if (this.current_state === states.stop) {
                    this.next_pulse = setTimeout(this.update, 20000);
                } else if (this.current_state === states.sleep) {
                    //ToDo: Watch if user is back -> Show overlay
                }
            },

            checkAccounts(serverTimeNow) {
                for (let i = 0; i < this.accounts.length; i++) {
                    const account = this.accounts[i];

                    if ((account['valid_until'] * 1000) - serverTimeNow < 0) {
                        this.updateAccounts();
                        return;
                    }
                }
            },

            togglePlay() {
                if (this.current_state === states.play) {
                    this.stop();
                } else {
                    this.play();
                }
            },

            setCover(img) {
                this.$refs.play_btn.style.backgroundImage = 'url("' + img + '")';
            },

            updateSession(){
                return this.$store.dispatch('joinSession', this.ssid);
            },

            updateAccounts() {
                this.$store.dispatch('listAccounts');
            },

            setProp(service, type, payload) {
                this.$store.commit('setAccountProp', {service, type, payload});
                player.init(this.accounts, this.account_props, this.setProp);
            },

            listArtists() {
                if (this.current_song.id === -1 || this.current_song.artists.length === 0) {
                    return 'Just Press Play';
                }


                let artists = '';

                for (let i = 0; i < this.current_song.artists.length; i++) {
                    if (i !== 0) {
                        artists += ',';
                    }

                    artists += ' ' + this.current_song.artists[i].name;
                }

                return artists;
            },

            initMediaSession() {
                this.updateMediaSession();
                this.attachMediaSessionHandlers();
            },

            updateMediaSession() {
                if ("mediaSession" in navigator) {
                    if (this.current_state === states.play) {
                        this.$refs.vanilla_player.play();
                        navigator.mediaSession.playbackState =  'playing';
                    } else {
                        this.$refs.vanilla_player.pause();
                        navigator.mediaSession.playbackState = 'paused';
                    }

                    navigator.mediaSession.metadata = new MediaMetadata({
                        title: this.current_song.title,
                        artist: this.listArtists(),
                        album: this.session.title,
                        artwork: [
                            { src: this.current_song.cover,   sizes: '300x300',   type: 'image/jpeg' },
                        ]
                    });
                }
            },

            clearMediaSession() {
                if ("mediaSession" in navigator) {
                    navigator.mediaSession.playbackState = 'paused';
                    navigator.mediaSession.metadata = null;
                }

                this.$refs.vanilla_player.pause();
            },

            attachMediaSessionHandlers() {
                if ("mediaSession" in navigator) {
                    const actionHandlers = [
                        ['play', () => {
                            this.play()
                        }],
                        ['pause', () => {
                            this.stop()
                        }],
                        ['nexttrack', () => {
                            this.skip()
                        }],
                    ];

                    for (const [action, handler] of actionHandlers) {
                        try {
                            navigator.mediaSession.setActionHandler(action, handler);
                        } catch (error) {
                            console.log(`The media session action "${action}" is not supported yet.`);
                        }
                    }
                }
            },

            async requestWakeLock() {
                if ('wakeLock' in navigator && !this.wakelock) {
                    this.wakelock = await navigator.wakeLock.request();
                }
            }
        },

        watch: {
            ssid(to, from) {
                if (to !== from) {
                    this.stop();
                }

                this.update();
            },

            accounts(to, from) {
                if (to.length !== 0){ //from.length === 0 &&
                    player.init(to, this.account_props, this.setProp);

                    if (this.current_state === states.play) {
                        this.play();
                    }
                }
            },
        },

        created() {
            if (this.accounts.length !== 0) {
                player.init(this.accounts, this.account_props, this.setProp);
            }

            this.update();
        },

        mounted() {
            this.update();
        },

        beforeDestroy() {
            this.stop();

            clearTimeout(this.next_pulse);
            this.next_pulse = null;

            this.clearMediaSession();
        }
    }
</script>
