<template>
    <div :class="wrapClasses">
        <div class="card_head">
            <b class="card_title"><Back prev="accounts"></Back>{{service.name}} <button class="card_title_btn" @click="save">save</button></b>
        </div>
        <div class="card_body">
            <label href="javascript:void(0)" class="switch_item" v-for="playlist in playlists">
                <input type="checkbox" class="switch_check_box" v-model="playlist.load">
                <span class="switch_item_title">{{playlist.name}}</span>
                <div class="switch">
                    <span class="lever"></span>
                </div>
            </label>
        </div>
    </div>
</template>

<script>
    import Back from "../../elements/Back.vue";

    export default {
        name: "SelectPlaylists",
        components: {Back},
        computed: {
            isApp() {
                return this.$route.name.includes('app');
            },

            wrapClasses() {
                let classes = ['card'];

                if (this.isApp) {
                    classes.push('card_full');
                }

                return classes.join(' ');
            }
        },

        data() {
            return {
                'service': {
                    name: 'Spotify'
                },
                'playlists': [],
            };
        },

        methods: {
            save() {
                const selected = this.playlists;

                this.playlists = [];

                this.$store.dispatch('saveSpotifyPlaylists', selected).then(() => {
                    setTimeout(this.getPlaylists, 600);
                });
            },

            getPlaylists() {
                this.$store.dispatch('listSpotifyPlaylists').then((data) => {
                    this.playlists = data;
                });
            },

        },

        created() {
            this.getPlaylists();
        }

    }
</script>