export default {
    user: state => state.user,
    active: state => state.active,
    session: state => state.session,
    votes: state => state.votes,
    invites_incoming: state => state.invites.incoming,
    invites_favorites: state => state.invites.favorites,
    invites_badge: state => state.invites.badge,
    accounts: state => state.accounts,
    account_props: state => state.account_props,
    playlists: state => state.playlists,
    friends: state => state.friends,
};
