<template>
    <div class="site_content_wrap">
        <main class="site_content">
            <Form
                @submit="handleSubmit"
                @mode="handleMode"
                :initial="initial"
                ref="form"
            >
                <TextInput
                    @change="handleChange"
                    :mode="mode"
                    :type="getTypes.USER"
                    label="Username"
                    name="username"
                    required="true"
                />
                <TextInput
                   @change="handleChange"
                   :mode="mode"
                   :type="getTypes.EMAIL"
                   label="E-Mail"
                   name="email"
                   required="true"
                />
                <TextInput
                    @change="handleChange"
                    :mode="mode"
                    :type="getTypes.PASSWORD"
                    label="Password"
                    name="password"
                    required="true"
                />
                <Submit :mode="mode" label="Create" icon="assignment_turned_in"/>
            </Form>
        </main>
    </div>
</template>

<script>
    import Form from "../elements/form/Form.vue";
    import TextInput from "../elements/form/TextInput.vue";
    import Submit from "../elements/form/Submit.vue";
    import {FORM_MODE, INPUT_TYPE} from "../../objects/form";

    export default {
        name: "Activate",
        components: {Form, TextInput, Submit},
        computed: {
            code() {
                return this.$route.params.code;
            },
            getTypes() {
                return INPUT_TYPE;
            }
        },

        data() {
            return {
                mode: FORM_MODE.DEFAULT,
                initial: {},
            }
        },

        methods: {
            handleSubmit(values) {
                this.$store.dispatch('claimTicket', {code: this.code, data: values}).then((data) => {
                    if (typeof data['success'] === 'undefined') {
                        this.setMode(FORM_MODE.RETRY);
                    } else {
                        this.setMode(FORM_MODE.SUCCESS);
                        this.$router.push({name: 'login'});
                    }
                }).catch((err) => {
                    this.setMode(FORM_MODE.RETRY);
                });
            },

            handleMode(mode) {
                this.mode = mode;
            },

            handleChange({name, value}) {
                this.$refs.form.setValue(name, value);
            },

            setMode(mode) {
                this.$refs.form.setMode(mode);
            },
        },
    }
</script>
