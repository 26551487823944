<template>
    <div class="how_to_view">
        <div class="how_to_item" v-for="item in items" :key="item.id">
            <img class="how_to_img" :src="item.icon" />
            <span class="how_to_desc">{{item.desc}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HowToView",

        data() {
            return {
                items: [
                    {id: 0, icon: '/img/how-to-step1.png', desc: 'Join or Create Session'},
                    {id: 1, icon: '/img/how-to-step2.png', desc: 'Start Playing'},
                    {id: 2, icon: '/img/how-to-step3.png', desc: 'Vote existing or new Songs'},
                ],
            }
        },
    }
</script>