export const FORM_MODE = {
    DEFAULT: 'default',
    LOADING: 'loading',
    RETRY: 'retry',
    SUCCESS: 'success',
};

export const INPUT_TYPE = {
    TEXT: 'text',
    EMAIL: 'email',
    USER: 'user',
    PASSWORD: 'password',
    PASSWORD_UNSAFE: 'password_unsafe',
}

export const INPUT_VALIDATION = {
    [INPUT_TYPE.TEXT]: null,
    [INPUT_TYPE.EMAIL]: null,
    [INPUT_TYPE.USER]: {pattern: '^[a-zA-Z][a-zA-Z0-9-_\\.]{1,20}$', label: '2-20 Chars'},
    [INPUT_TYPE.PASSWORD]: {pattern: '(?=.*\\d)(?=.*[a-zA-Z]).{6,}', label: 'Uppercase, Lowercase, Number'},
    [INPUT_TYPE.PASSWORD_UNSAFE]: null,
}
