<template>
    <div class="inline_select_wrap">
        <button v-for="(option, index) in options" :class="itemClass(index)" :key="index" @click="select(index)">{{option.title || option.icon}}</button>
    </div>
</template>

<script>
    export default {
        name: "InlineSelect",

        props: ['value', 'options'],

        methods: {
            itemClass(index) {
                return `inline_select_item ${this.selected(index) && 'inline_select_item_selected'} ${this.iconMode(index) && 'inline_select_item_icon'}`;
            },

            selected(index) {
                return this.options[index].key === this.value;
            },

            iconMode(index) {
                return typeof this.options[index].title === 'undefined';
            },

            select(index) {
                this.$emit('input', this.options[index].key);
            }
        }
    }
</script>
