<template>
    <div class="toggle_view_page">
        <div class="toggle_page_title">Options</div>
        <div class="settings_item" v-if="optionVisible('general')">
            <div class="settings_item_title">
                {{$t('session.admin.general')}}
            </div>
            <div class="list">
                <input type="text" :placeholder="$t('session.admin.name')" v-model="edit.title" ref="name" required>
                <ActionButton
                    :title="$t('session.managed.upgrade.title')"
                    :desc="$t('session.managed.upgrade.desc')"
                    icon="admin_panel_settings"
                    @click="$router.push({name: 'upgrade', params: {ssid: session.id}})"
                />
                <button class="txt_btn">{{$t('session.admin.delete')}}</button>
            </div>
        </div>

        <SpotifyConnect @play="$emit('play')" @stop="$emit('stop')"></SpotifyConnect>

        <div class="session_options_admin" v-if="isManaged">
            <ManagedList :list="edit.mods" :title="$t('session.moderation.moderator')" @add="addMod" v-if="optionVisible('policy') && !open_add.mods"/>
            <SelectUser :users="friends" :title="$t('session.moderation.moderator')" @select="selectMod" v-if="open_add.mods"/>

            <ManagedList :list="edit.flavor.selected" :title="$t('session.flavor.title')" @add="addFlavor" v-if="optionVisible('policy') && !open_add.flavors">
                <InlineSelect v-model="edit.flavor.mode" :options="flavor_modes"/>
            </ManagedList>
            <SelectFlavor :title="$t('session.flavor.title')" @select="selectFlavor" v-if="open_add.flavors"/>

            <ManagedList :list="edit.banned" :title="$t('session.banned.title')" @add="addBanned" v-if="optionVisible('policy') && !open_add.banned"/>
            <SelectUser :users="session.active" :title="$t('session.banned.title')" @select="selectBanned" v-if="open_add.banned"/>

            <div class="settings_item" v-if="optionVisible('policy')">
                <div class="settings_item_title">{{$t('session.admin.policy')}}</div>
                <InlineSelect v-model="edit.policy" :options="policies"/>
            </div>

            <div class="settings_item" v-if="'share' in edit">
                <div class="settings_item_title">{{$t('session.admin.downloads.title')}}</div>
                <ActionButton :title="$t('session.admin.downloads.qr.title')"
                              :desc="$t('session.admin.downloads.qr.desc')" icon="qr_code" @click="window.open(edit.share.qr, '_blank')"/>
                <ActionButton :title="$t('session.admin.downloads.pdf.title')"
                              :desc="$t('session.admin.downloads.pdf.desc')" icon="pages" @click="window.open(edit.share.pdf, '_blank')"/>
            </div>
        </div>

        <button class="fab_btn fab_btn_sticky" v-if="changed" @click="save">save</button>
    </div>
</template>

<script>
    import InlineSelect from "../../elements/InlineSelect.vue";
    import SpotifyConnect from "../../elements/SpotifyConnect.vue";
    import ManagedList from "../../elements/ManagedList.vue";
    import ActionButton from "../../elements/ActionButton.vue";
    import SelectUser from "../../elements/SelectUser.vue";
    import SelectFlavor from "../../elements/SelectFlavor.vue";

    const visibleOption =  {
        general: ['manager', 'operator'],
        policy: ['manager'],
        mod: ['manager'],
        flavor: ['manager'],
        banned: ['manager', 'operator'],
        qr: ['manager', 'operator', 'relate'],
    };

    export default {
        name: "Options",

        components: {SelectFlavor, SelectUser, ActionButton, ManagedList, SpotifyConnect, InlineSelect},

        computed: {
            session() {
                return this.$store.getters.session;
            },

            friends() {
                return this.$store.getters.friends;
            },

            isManaged() {
                return this.session.mode && this.session.mode === 'managed'
            },
        },

        data() {
            return {
                edit: {
                    title: '',

                    flavor: {
                        mode: 'disabled',
                        selected: []
                    },

                    policy: 'free',

                    mods: [],
                    banned: [],
                },

                original: null,
                changed: false,

                flavor_modes: [
                    {key: 'blacklist', title: 'Blacklist'},
                    {key: 'whitelist', title: 'Whitelist'},
                    {key: 'disabled', title: 'Liberal'}
                ],

                policies: [
                    {key: 'strict', title: 'Strict'},
                    {key: 'invite', title: 'Invited'},
                    {key: 'link', title: 'Link/QR'},
                    {key: 'free', title: 'Free'},
                ],

                open_add: {
                    mods: false,
                    flavors: false,
                    banned: false
                },

                loading: false,
            }
        },

        methods: {
            load() {
                this.loading = true;

                this.$store.dispatch('getSessionOptions', {ssid: this.session.id}).then((data) => {
                    this.applyValues(data);
                    this.loading = false;
                }).catch(err => {
                    this.loading = false;
                });
            },

            save() {
                if (this.validate()) {
                    this.loading = true;
                    this.$store.dispatch('setSessionOptions', {ssid: this.session.id, options: this.getClean()}).then((data) => {
                        this.applyValues(data);
                        this.loading = false;
                    }).catch(err => {
                        this.loading = false;
                    });
                }
            },

            applyValues(data) {
                this.edit.title = data.title;
                this.edit.policy = data.policy;
                this.edit.flavor.mode = data.flavor.mode;

                this.edit.flavor.selected = data.flavor.selected.map(item => {
                    let full = this.attachDefaultActions(item, this.removeFlavor);

                    if (!full['title']) {
                        full['title'] = full.name;
                    }

                    return full;
                });

                this.edit.mods = data.mods.map(item => {
                    return {
                        ...this.attachModActions(item),
                        title: item.username
                    };
                });

                this.edit.banned = data.banned.map(item => {
                    return {
                        ...this.attachDefaultActions(item, this.removeBanned),
                        title: item.username
                    };
                });


                this.original = JSON.stringify(this.getClean());
            },

            checkLeave() {
                if (this.hasChanged(this.getClean()) && confirm(this.$t('generic.save.confirm'))) {
                    this.save();
                }
            },

            validate() {
                return [
                    this.$refs.name
                ].every(el => {
                    return el.reportValidity();
                })
            },

            getClean() {
                return {
                    title: this.edit.title,
                    policy: this.edit.policy,
                    flavor: {
                        selected: this.detachedDefaultList(this.edit.flavor.selected),
                        mode: this.edit.flavor.mode,
                    },
                    mods: this.detachedModList(),
                    banned: this.detachedDefaultList(this.edit.banned)
                };
            },

            sessionChanged() {

            },

            hasChanged(clean) {
                this.changed = this.original !== null && this.original !== JSON.stringify(clean);
                return this.changed;
            },

            // MODS

            addMod() {
                this.open_add.mods = true;
            },

            selectMod(selected) {
                this.edit.mods.push(this.attachModActions({
                    ...selected,
                    level: 'relate'
                }));
                this.open_add.mods = false;
            },

            setModAbility(option, index) {
                this.edit.mods[index].level = option;
            },

            removeMod(index) {
                this.edit.mods.splice(index, 1);
            },

            attachModActions(mod) {
                const actions = mod.level === 'owner' ? {
                    type: 'icon',
                    icon: 'perm_identity',
                } : {
                    type: 'dropdown',
                    key: 'level',
                    options: [
                        {key: 'manager', value: 'Manager'},
                        {key: 'operator', value: 'Operator'},
                        {key: 'relate', value: 'Relate'}
                    ],
                    changed: this.setModAbility
                };

                return {
                    ...mod,
                    remove: mod.level === 'owner' ? null : this.removeMod,
                    actions: [actions]
                }
            },

            detachedModList() {
                return this.edit.mods.slice().map(mod => {
                    const {remove, actions, ...copy} = mod;
                    return copy;
                });
            },

            // FLAVORS

            addFlavor() {
                this.open_add.flavors = true;
            },

            selectFlavor(flavor) {
                this.edit.flavor.selected.push(this.attachDefaultActions({
                    ...flavor
                }, this.removeFlavor));
                this.open_add.flavors = false;
            },

            removeFlavor(index) {
                this.edit.flavor.selected.splice(index, 1);
            },


            // BANNED

            addBanned() {
                this.open_add.banned = true;
            },

            selectBanned(selected) {
                this.edit.banned.push(this.attachDefaultActions(selected, this.removeBanned));
                this.open_add.banned = false;
            },

            removeBanned(index) {
                this.edit.banned.splice(index, 1);
            },

            // DEFAULT MANAGED

            attachDefaultActions(original, remove) {
                return {
                    ...original,
                    remove
                }
            },

            detachedDefaultList(original) {
                return original.slice().map(item => {
                    const {remove, ...copy} = item;
                    return copy;
                });
            },

            optionVisible(option) {
                return this.session.setup.manager === 'owner' || visibleOption[option].includes(this.session.setup.manager);
            }
        },

        watch: {
            edit: {
                deep: true,

                handler() {
                    this.hasChanged(this.getClean());
                }
            },
        },

        mounted() {
            this.load();
        }
    }
</script>
