<template>
    <div class="toggle_view_page">
        <div class="toggle_page_title">Your Playlists</div>
        <Playlist class="library_playlist" v-for="(playlist, index) in playlists" :key="index" :playlist="playlist" v-bind:show="open === index" v-bind:open="setOpen" @update="$emit('update')"></Playlist>
    </div>
</template>

<script>
    import Playlist from "../../elements/Playlist.vue";

    export default {
        name: "Library",
        components: {Playlist},

        data() {
            return {
                playlists: [],
                open: -1,
            }
        },

        methods: {
            getPlaylists() {
                this.$store.dispatch('listPlaylists').then((data) => {
                    this.playlists = data;
                });
            },

            setOpen(index) {
                this.open = index;
            },
        },

        created() {
            this.getPlaylists();
        },
    }
</script>