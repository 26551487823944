<template>
    <div class="refresh_wrap" ref="wrap">
        <div class="refresh_container" ref="container">
            <Load :size="pull_size" />
        </div>
        <div class="refresh_content" ref="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import Load from "./Load.vue";

    const PULL_CAPS = {
        DRAG: -12,
        DROP: 64,
    };

    export default {
        name: "RefreshView",

        components: {Load},

        data() {
            return {
                dragged: false,
                loading: false,
                pull_size: 0,
                start_y: 0,
                current_y: 0,
            }
        },

        methods: {
            dragStart(event) {
                if (!this.loading && this.$refs.content.scrollTop === 0) {
                    this.start_y = this.getPos(event);
                    this.dragged = true;
                }
            },

            drag(event) {
                if (this.dragged) {
                    this.current_y = this.getPos(event);
                    const temp_pull = this.start_y - this.current_y;

                    if (temp_pull <= (0 - PULL_CAPS.DROP)) {
                        this.pull_size = Math.abs(temp_pull);
                        this.drop();
                    } else if (temp_pull < PULL_CAPS.DRAG) {
                        this.pull_size = Math.abs(temp_pull);
                        this.applyPullSize();
                    }
                }
            },

            drop() {
                if (this.dragged) {
                    if (this.pull_size >= PULL_CAPS.DROP) {
                        this.pullLoad();
                    } else {
                        this.resetPull();
                    }

                }
            },

            pullLoad() {
                this.pull_size = PULL_CAPS.DROP;
                this.start_y = 0;
                this.current_y = 0;
                this.loading = true;
                this.dragged = false;
                setTimeout(() => {
                    this.$emit('refresh', this.refreshWorker);
                }, 120);
            },

            resetPull() {
                this.dragged = false;
                this.loading = false;
                this.pull_size = 0;
                this.start_y = 0;
                this.current_y = 0;
                this.applyPullSize();
            },

            refreshWorker(callback) {
                callback.then(() => {
                    this.resetPull();
                }).catch(() => {
                    this.resetPull();
                });
            },

            getPos(event) {
                return event.clientY ? event.clientY : event.touches[0].clientY;
            },

            applyPullSize() {
                this.$refs.container.style.height = this.pull_size + 'px';
                this.$refs.content.style.height = 'calc(100% - ' + this.pull_size + 'px)';
            }
        },

        mounted() {
            //DRAG N DROP
            this.$refs.wrap.onmousedown = event => { this.dragStart(event) };
            this.$refs.wrap.onmouseup = event => { this.drop(event) };
            this.$refs.wrap.onmouseleave = event => { this.drop(event) };
            this.$refs.wrap.onmousemove = event => { this.drag(event) };

            this.$refs.wrap.ontouchstart = event => { this.dragStart(event) };
            this.$refs.wrap.ontouchend = event => { this.drop(event) };
            this.$refs.wrap.ontouchmove = event => { this.drag(event) };
        },
    }
</script>
