<template>
    <div class="settings_item">
        <div class="settings_item_title">Spotify Connect <button class="icon_btn right" @click="getList">refresh</button></div>
        <div class="list">
            <div class="list_item" v-for="(device, index) in devices">
                <input type="radio" name="spfy_connect" @click="select(index)" :id="'r' + index" :checked="check(index)" />
                <label :for="'r' + index">
                    {{device.name}}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import spotify from "../../objects/spotify.js";

    export default {
        name: "SpotifyConnect",

        data() {
            return {
                devices: [],
            }
        },

        methods: {

            getList() {
                spotify.loadDeviceList().then(() => {
                    this.load();
                });
            },

            load() {
                if (spotify.props !== undefined) {
                    this.devices = spotify.props.devices;
                }
            },

            select(i) {
                const active = spotify.active;

                if (active) {
                    this.$emit('stop');
                }

                spotify.setDevice(this.devices[i].id);

                if (active) {
                    this.$emit('play');
                }
            },

            check(i) {
                return this.devices[i].id === spotify.getDevice();
            }
        },

        mounted() {
            this.load();
        }

    }
</script>