<template>
    <label :class="getLabelClass">
        <span class="label">{{getLabelUppercase}}</span>
        <input
            v-model="value"
            :type="getType"
            :class="getInputClass"
            :placeholder="getLabel"
            :name="name"
            :pattern="getValidation"
            :title="getTitle"
            :disabled="isDisabled"
            :required="required"
        />
    </label>
</template>

<script>
    import {FORM_MODE, INPUT_TYPE, INPUT_VALIDATION} from "../../../objects/form";

    const INPUT_FEATURES = {
        [INPUT_TYPE.TEXT]: {
            type: 'text',
        },
        [INPUT_TYPE.EMAIL]: {
            type: 'email',
        },
        [INPUT_TYPE.PASSWORD]: {
            type: 'password',
        },
        [INPUT_TYPE.PASSWORD_UNSAFE]: {
            type: 'password',
        },
        [INPUT_TYPE.USER]: {
            type: 'text',
        }
    };

    const MODE_FEATURES = {
        [FORM_MODE.DEFAULT]: {
            disabled: false,
        },
        [FORM_MODE.LOADING]: {
            disabled: true,
        },
        [FORM_MODE.RETRY]: {
            disabled: false,
        },
        [FORM_MODE.SUCCESS]: {
            disabled: true,
        }
    };

    export default {
        name: "TextInput",

        props: ['name', 'label', 'type', 'mode', 'required', 'classes'],

        computed: {
            isDisabled() {
                return MODE_FEATURES[this.mode] && MODE_FEATURES[this.mode].disabled;
            },

            getValidation() {
                return INPUT_VALIDATION[this.type] && INPUT_VALIDATION[this.type].pattern;
            },

            getTitle() {
                return INPUT_VALIDATION[this.type] && INPUT_VALIDATION[this.type].label;
            },

            getType() {
                return INPUT_FEATURES[this.type] && INPUT_FEATURES[this.type].type;
            },

            getLabel() {
                return this.$t(this.label);
            },

            getLabelUppercase() {
                return this.getLabel.toUpperCase();
            },

            getLabelClass() {
                return `${this.classes && this.classes.label || ''}`;
            },

            getInputClass() {
                return `input_spread ${this.classes && this.classes.input || ''}`;
            },
        },

        data() {
            return {
                value: ''
            }
        },

        watch: {
            value(to) {
                this.$emit('change', {name: this.name, value: to});
            }
        }
    }
</script>
