import Vue from 'vue';
import * as axios from 'axios';
import store from './store';
import router from './router';
import VueLocalStorage from 'vue-localstorage';
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';
import i18n from "./objects/i18n";
import '../sass/main.scss';


Vue.use(VueRouter);
Vue.use(VueLocalStorage);
Vue.use(VueAxios, axios);

axios.interceptors.request.use(function (config) {
    const token = localStorage.token;

    if (token !== 'null') {
        config.headers['X-Auth-Token'] = token;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

new Vue({
    router,
    i18n,
    store,
    el: '.app',
});
