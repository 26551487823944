<template>
    <div>
        <div class="toggle_page_title" >Online Now</div>
        <div class="toggle_page_content">
            <FriendItem v-for="(active, index) in session.active" :key="index" :user="active"></FriendItem>
        </div>
        <ActionButton :title="$t('session.share.native.title')" :desc="$t('session.share.native.desc')" icon="share" :loading="loading" @click="nativeShare" v-if="hasNativeShare"/>
        <ActionButton :title="$t('session.share.invite.title')" :desc="$t('session.share.invite.desc')" icon="group_add" @click="openInvites"/>
    </div>
</template>

<script>
    import FriendItem from "../../elements/FriendItem.vue";
    import ActionButton from "../../elements/ActionButton.vue";

    export default {
        name: "Active",
        components: {ActionButton, FriendItem},
        props: ['openInvites'],

        computed: {
            session() {
                return this.$store.getters.session;
            },

            hasNativeShare() {
                return navigator.share;
            },

            sessionUrl() {// ToDo: Request from API?
                return `https://sympe.us/#/main/${this.session.uuid}`;
            }
        },

        data() {
            return {
                loading: false
            }
        },

        methods: {

            nativeShare() {
                if (!navigator.share) {
                    return;
                }

                navigator.share({
                    title: this.$t('session.share.message.title'),
                    text: this.$t('session.share.message.desc', {name: this.session.title}),
                    url: this.sessionUrl
                });
            },
        },
    }
</script>
